import React, { useEffect } from 'react';
import {
    Typography, Button, Grid,
    OutlinedInput,
    InputAdornment,
    IconButton
} from '@mui/material';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { StickerContainer, StickerTableHeader } from '../../../GlobalSetup/Components';
import { getMaterialFilters, getPromoTypes } from '../../../../../redux/actions';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Market, PromoData } from '../../../ReviewScenario/Components/';
import Moment from 'moment';
import { ArrowDropDownTwoTone, ArrowDropUpTwoTone, List } from '@mui/icons-material';
import { PromoSelect, SingleSelectList } from '../../../Components';
import { getPromoStickers, getTRApprovalData, getTRApprovalReviewData, updateTrApprovalStickerData } from '../../../../../redux/actions/Promotion/AuthAction';
import ReviewBox from './ReviewBox'
import TrContainer from './TrContainer'
import { BeatLoader, HashLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 16px',
        height: '100%',
        overflowX: 'hidden'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },

    mainHeader: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 50,
        border: '1px solid #E8E8E8',
        borderTop: 0,
        borderRadius: 2,
    },
    filterGrid: {
        backgroundColor: '#FFFFFF',
        border: '0.8px solid #E2E2E2',
        padding: 8
    },
    filterGridTop: {
        borderLeft: '0.8px solid #E2E2E2',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
    },
    detailGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    countBox: {
        width: 96,
        height: 28,
        padding: '2px 10px',
        marginLeft: 'auto',
        marginRight: 10,
        borderRadius: 16,
        border: '.8px solid black',
        backgroundColor: 'white',
    },
    defaultContainer: {
        display: 'grid',
        justifyContent: 'center',
        height: 300,

    },
    skeletonContainer: {
        justifyContent: 'center',
        display: 'inline-grid',
        alignContent: 'center',
        //width: 300
    },
    reviewBox: {
        backgroundColor: '#F8F8F8',
        marginLeft: 5,
        display: 'flex',
        minHeight: '904px',
        padding: 0
    },
    headContainer: {
        marginLeft: '5px',
        backgroundColor: '#F8F8F8',
        height: 50,
        border: '1px solid #E8E8E8',
        borderTop: 0,
        borderBottom: 0,
        borderRadius: 2,
    },
    stickerName: {
        marginTop: 10,
        marginLeft: 25,
    },
    btn: {
        height: 33,
        marginRight: 5
    },
    selectBox: {
        height: 37,
        marginRight: 40,
        paddingLeft: 5,
        border: "1px solid",
        borderRadius: 2,
        backgroundColor: 'white',
        flexDirection: 'row',
        display: 'flex',
    },
    prefillOutlined: {
        width: '100%',
        height: '37px',
        border: '0px'
    },
    leftBox: {
        width: '75%'
    },
    rightBox: {
        width: '25%',
        border: '1px solid black'
    },
    spinner: {
        height: '100vh'
    },

}));


const TakesRateApproval = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState('USA');
    const [count, setCount] = React.useState(0);
    const [modelData, setModelData] = React.useState('');
    const [completdModelData, setCompletedModelData] = React.useState('');
    const [prefillId, setPrefillId] = React.useState('');
    const [anchorE1, setAnchorE1] = React.useState(false);
    const [scenarioExpand, setScenarioExpand] = React.useState([]);
    const [scenarios, setScenarios] = React.useState('');
    const [scenariosID, setScenariosID] = React.useState('');
    const [stickerName, setStickerName] = React.useState('');
    const [data, setData] = React.useState([]);
    const [pendingStickers, setPendingStickers] = React.useState([]);
    const [completedStickers, setCompletedStickers] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([]);
    const [headerData, setHeaderData] = React.useState([]);
    const [takeRate, setTakeRate] = React.useState('');
    const [flagCom, setFlagCom] = React.useState(false);
    const [flagPen, setFlagPen] = React.useState(false);
    const [flagStick, setFlagStick] = React.useState(false);
    const [loaderMarket, setLoaderMarket] = React.useState(false);
    const [loaderSticker, setLoaderSticker] = React.useState(false);
    const [stickerID, setStickerID] = React.useState('');
    const format = { 'options': [{ promotion_type: '' }] }

    useEffect(() => {
        props.getFilters()
    }, []);

    useEffect(() => {
        if (productType && selectedCountry)
            props.getTrApprovalDropdownData(productType, selectedCountry)
        setModelData([])
        setCompletedModelData([])
        setCount(0)
    }, [productType, selectedCountry]);

    useEffect(() => {
        if (props.selectedCountry && props.productType && props.selectedCountry.length > 0 && props.productType.length > 0)
            props.getPromotionTypes(props.selectedCountry, props.productType);

    }, [props.selectedCountry, props.productType]);


    useEffect(() => {
        if (stickerID) {
            setLoaderSticker(true)
            props.getStickers(productType, selectedCountry, stickerID, null, null, 'TR')
            setFlagStick(true)
        }
    }, [stickerID]);

    useEffect(() => {
        setLoaderMarket(false)
        if (props.trApprovalReviewList && flagPen)
            setModelData(props.trApprovalReviewList)
        else
            setModelData({})
    }, [props.trApprovalReviewList]);

    useEffect(() => {
        setLoaderMarket(false)
        if (props.trApprovalReviewCompletedList && flagCom)
            setCompletedModelData(props.trApprovalReviewCompletedList)
        else
            setCompletedModelData({})
    }, [props.trApprovalReviewCompletedList]);

    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setProductLinesList(props.filterData['material_master'][0]['material_group'])
            setProductType(props.filterData['material_master'][0]['material_group'][0])
        }
    }, [props.filterData]);

    useEffect(() => {
        if (props.trApprovalList) {
            setData(props.trApprovalList)
            setScenarios('')
            handleClearSticker()
        }
        else {
            setData([])
            setScenarios('')
            handleClearSticker()
        }

    }, [props.trApprovalList])

    useEffect(() => {
        setLoaderSticker(false)
        if (props.sticker && flagStick) {
            setHeaderData(props.sticker)
            setStickerData(props.sticker.sticker_type)
            setStickerName(props.sticker.sticker_description)
            setTakeRate(props.sticker.takerate_name)
        }
        else {
            handleClearSticker()
        }
    }, [props.sticker])

    function handleClearSticker() {
        var headerformData = {
            ...headerData,
            "sticker_type": [format],
        };
        setHeaderData(headerformData)
        setStickerName('')
        setTakeRate('')
        setStickerData([])
    }

    function handleChange(e) {
        setProductType(e.target.value);
    }

    function handleReturn() {
        history.push('/promotion-dashboard')
    }

    function handleSelectedCountry(e) {
        setSelectedCountry(e);
    }


    function handleScenario(checked, id, s) {
        var arrPending = []
        var arrCompleted = []
        s && s.length > 0 && s.forEach(e => {
            if (e.status === 'completed')
                arrCompleted.push(e.sticker_label)
            else
                arrPending.push(e.sticker_label)
        });
        if (id) {
            setScenarios(checked)
            setScenariosID(id)
            setPendingStickers(arrPending)
            setCompletedStickers(arrCompleted)
        }
        else {
            setScenarios('')
            setScenariosID('')
            setPendingStickers('')
            setCompletedStickers('')
        }
        setScenarioExpand([])
        setAnchorE1(false)
        var formaData1 = {
            scenario_id: id,
            sticker_labels: arrPending,
            sticker_status: "pending",
            country: selectedCountry,
            product_line: productType,
        }
        var formaData2 = {
            scenario_id: id,
            sticker_labels: arrCompleted,
            sticker_status: "completed",
            country: selectedCountry,
            product_line: productType,
        }

        props.getTRApprovalReviewData(formaData1, 'pending')
        setFlagPen(true)
        setLoaderMarket(true)

        handleClearSticker()
    }

    function handleReview(e) {
        var formaData1 = {
            scenario_id: scenariosID,
            sticker_labels: pendingStickers,
            sticker_status: "pending",
            country: selectedCountry,
            product_line: productType,
        }
        var formaData2 = {
            scenario_id: scenariosID,
            sticker_labels: completedStickers,
            sticker_status: "completed",
            country: selectedCountry,
            product_line: productType,
        }
        if (e === 'completed' && completedStickers && scenariosID) {
            props.getTRApprovalReviewData(formaData2, 'completed')
            setFlagCom(true)
            setLoaderMarket(true)
        }
        else if (e === 'pending' && pendingStickers && scenariosID) {
            props.getTRApprovalReviewData(formaData1, 'pending')
            setFlagPen(true)
            setLoaderMarket(true)
        }
    }

    const handleClick = () => {
        setAnchorE1(!anchorE1)
        const isExpanded = !anchorE1
        setScenarioExpand(isExpanded ? [...scenarioExpand, 'scenario_filter'] : []);
    };

    // function handleStickerDesc(desc) {
    //     setStickerName(desc)
    // }

    function handleStickerData(value, takerate) {
        setStickerData(value)
        setTakeRate(takerate)
    }

    function handleSticker(id) {
        // var headerformData = {
        //     ...headerData,
        //     "sticker_type": [format],
        // };
        // setHeaderData(headerformData)
        // setStickerName('')
        // setTakeRate('')
        // setStickerData([])
        setStickerID(id)
    }

    function handleAPICall(tempPending, tempCompleted) {
        var formaData1 = {
            scenario_id: scenariosID,
            sticker_labels: tempPending,
            sticker_status: "pending",
            country: selectedCountry,
            product_line: productType,
        }
        var formaData2 = {
            scenario_id: scenariosID,
            sticker_labels: tempCompleted,
            sticker_status: "completed",
            country: selectedCountry,
            product_line: productType,
        }


        const delayedAPICall = () => {
            if (tempPending && scenariosID) {
                props.getTRApprovalReviewData(formaData1, 'pending');
                setFlagPen(true);
                setLoaderMarket(true)
            }

            if (tempCompleted && scenariosID) {
                props.getTRApprovalReviewData(formaData2, 'completed');
                setFlagCom(true);
                setLoaderMarket(true)
            }
        };

        // Delay the API call by 2 seconds (5000 milliseconds)
        setTimeout(delayedAPICall, 2000);
    }


    function handleId(id, label) {

        const keysToFilterOut = ["CreatedAt", "DeletedAt", "ID", "user_details", "UpdatedAt", "created_by_id", "organization_id", "updated_by_id"];

        // Filter out the keys
        const filteredHeaderData = Object.fromEntries(
            Object.entries(headerData).filter(([key]) => !keysToFilterOut.includes(key))
        );

        var formData = {
            ...filteredHeaderData,
            // "sticker_name": stickerName,
            "sticker_type": stickerData,
            "takerate_name": takeRate
        };

        if (id && scenariosID) {
            props.updateTrApprovalStickerData(id, scenariosID, formData);
        }

        var tempCompleted = [...completedStickers]
        var tempPending = [...pendingStickers]

        if (!completedStickers.includes(label)) {
            tempCompleted.push(label);
        }

        const labelIndex = tempPending.indexOf(label);
        if (labelIndex !== -1) {
            tempPending.splice(labelIndex, 1);
        }
        setCompletedStickers(tempCompleted)
        setPendingStickers(tempPending)

        handleAPICall(tempPending, tempCompleted)

        handleClearSticker()
    }

    function handleCount(e) {
        setCount(e ? e : 0)
    }

    return (
        <LoadingOverlay
            active={props.loading && loaderSticker}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}

        >
            <div className={classes.root}>
                <StickerTableHeader country={selectedCountry} onCountryChange={handleSelectedCountry} productLineData={productLinesList}
                    defaultDivison={productType} onChange={handleChange} isDisabled={false} stickerName={"TakesRate Approval"} />

                <div className={classes.mainHeader}>
                    <div style={{ height: 34, marginTop: 8, marginLeft: 5, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', width: '80%' }}>
                            <Button style={{ marginLeft: 10 }} variant="outlined" startIcon={<ArrowLeftIcon />} onClick={handleReturn}>
                                Return
                            </Button>
                            <Typography style={{ fontSize: 13, marginTop: 3 }}>
                                <CircleIcon style={{ marginLeft: 15, height: 10, color: 'red' }} />
                                In Progress
                            </Typography>
                        </div>
                        {<>
                            <div className={classes.selectBox}>
                                <List style={{ marginTop: 4 }} />
                                <OutlinedInput
                                    twoline='true'
                                    value={scenarios}
                                    readOnly
                                    placeholder='None Selected'
                                    onClick={() => handleClick('scenario')}
                                    className={classes.prefillOutlined}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClick('scenario')}
                                            >
                                                {anchorE1 ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                            {anchorE1 &&
                                <div >
                                    <PromoSelect id={'prefill'} data={data} expand={scenarioExpand} onSubmit={handleScenario}
                                        prevalue={null} type='scenario_dropdown' />
                                    {/* <PromoSelect options={data}></PromoSelect> */}
                                </div>
                            }
                        </>
                        }
                    </div>
                </div>
                <div className={classes.headContainer}>
                    <Grid item xs={12} md={12} container>
                        <Grid item xs={12} md={9} >
                            <Grid item xs={12} md={12} className={classes.stickerName}>STICKER NAME</Grid>
                            <Grid item xs={12} md={11} className={classes.stickerName}>
                                <OutlinedInput
                                    value={stickerName}
                                    // onChange={(event) => setStickerDesc(event.target.value)}
                                    style={{ width: '100%', backgroundColor: 'white', height: 45 }}
                                    readOnly
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton
                                //             aria-label="toggle password visibility"
                                //             // onClick={handleOnAdd}
                                //             edge="end"
                                //         >
                                //             <Button className={classes.btn} variant="contained">Add Stickers to TR Bank</Button>
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                                >

                                </OutlinedInput>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.filterGridTop}>
                            <Typography>TAKE RATE REVIEW</Typography>
                            <div className={classes.countBox}>{count} Entries</div>
                        </Grid>
                    </Grid>
                </div >
                <div className={classes.reviewBox}>
                    <Grid item xs={12} md={12} container>
                        <Grid item xs={12} md={9}>
                            <Typography style={{
                                marginTop: 70,
                                marginLeft: 25,
                                marginBottom: 40,
                            }}>STICKER TAKE RATE FOCUS</Typography>
                            <div style={{ marginLeft: 20 }}>
                                <TrContainer country={selectedCountry} productLines={productType} onChange={handleStickerData} data={headerData} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.filterGrid}>
                            <LoadingOverlay
                                active={props.loading && loaderMarket}
                                styles={{
                                    spinner: (base) => ({
                                        ...base,
                                        width: '50px',
                                        '& svg circle': {
                                            stroke: '#045FB4'
                                        }
                                    }),
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(52, 52, 52, 0)'
                                    }),
                                    content: (base) => ({
                                        ...base,
                                        color: 'black'
                                    })
                                }}
                                spinner={<BeatLoader />}
                                className={classes.spinner}

                            >
                                <ReviewBox
                                    pendingData={modelData}
                                    completedData={completdModelData}
                                    onChange={handleReview}
                                    onSet={handleSticker}
                                    review={handleId}
                                    reviewStatus={handleCount}
                                // stickers={promoStickerData}
                                />
                            </LoadingOverlay>
                        </Grid>
                    </Grid>
                </div>
            </div >
        </LoadingOverlay>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        getTrApprovalDropdownData: (productType, selectedCountry) => dispatch(getTRApprovalData(productType, selectedCountry)),
        getTRApprovalReviewData: (data, type) => dispatch(getTRApprovalReviewData(data, type)),
        updateTrApprovalStickerData: (id, scenarioId, data,) => dispatch(updateTrApprovalStickerData(id, scenarioId, data)),
        getStickers: (productType, country, id, pagination, limit, page) => dispatch(getPromoStickers(productType, country, id, pagination, limit, page)),
    }
}

const mapStateToProps = state => {
    return {
        trApprovalReviewList: state.promotionData.trApprovalReviewData,
        trApprovalReviewCompletedList: state.promotionData.trApprovalReviewCompletedData,
        trApprovalList: state.promotionData.trApprovalData,
        filterData: state.promotionData.promoFilters,
        configData: state.calculationAnalysisData.budgetData,
        sticker: state.promotionData.stickerData,
        loading: state.promotionData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TakesRateApproval);