import React, { useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import { Button, Card, Typography, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, FormLabel, Select, MenuItem, OutlinedInput, ListItemText, Checkbox } from '@mui/material';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import { ChipInput } from "../../components/Inputs";
import { createManEligRulesDesigner, getLblDispDesFieldValue, getManEligRulesDesigner, getDefaultValuesAllData } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '20px 16px',
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 41,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
        fontSize: theme.typography.h4.fontSize
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    gridContainer: {
        width: '100%'
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 5,
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));
const MandatoryEligibilityRules = props => {
    const classes = useStyles();
    const { className } = props;
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [currentID, setCurrentID] = React.useState(0);
    const [workflowName, setWorkflowName] = React.useState([]);
    const [workflowNameArray, setWorkflowNameArray] = React.useState([]);
    const [secondApiDropDownValues, setSecondApiDropDownValues] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [listData, setListData] = React.useState([]);
    const [putActive, setPutActive] = React.useState(false);
    const [applicationType, setApplicationType] = React.useState('');
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [formFieldsAllData, setFormFieldsAllData] = React.useState([]);
    const [keyValuePairObjectNew, setKeyValuePairObjectNew] = React.useState([]);
    // const isAllSelected = workflowNameArray && Object.entries(workflowNameArray).length > 0 && selected.length === Object.entries(workflowNameArray).length;
    const isAllSelected =
        Object.keys(keyValuePairObjectNew).length > 0 && Object.keys(keyValuePairObjectNew).length === selected.length;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);

    useEffect(() => {
        if (props.formFieldsAllData && props.formFieldsAllData.records) {
            setFormFieldsAllData(props.formFieldsAllData)
        }
    }, [props.formFieldsAllData])
    useEffect(() => {
        var tempValues = [];
        var tempKeys = [];
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.length > 0) {

            setKeyValuePairObjectNew(props.formFieldsAllData.records[0].label_names)
        }
    }, [props.formFieldsAllData])


    useEffect(() => {
        var data = {
            "data_source": "document_header",
            "application_type": "Customer Rebate",
            "assignment_workflow": true
        }
        props.fieldDesigner(data, 'post');
        props.getClaimData();
        props.getDefaultValuesAllData('attribute_name', 'Direct - Sales Data', 'Customer Rebate');
    }, [])

    useEffect(() => {
        let newArray = []
        if (props.claimData && props.claimData.length > 0) {
            props.claimData.forEach(data => {
                if (data.default_configuration) {
                    newArray.push({
                        id: data.id,
                        ...data.default_configuration,
                    })
                }
            });
            setListData(newArray)
        }
    }, [props.claimData]);
    useEffect(() => {
        var newArray = {}
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display && v.key !== 'application_type' && v.key !== 'document_analyst')
                .map(([k, v]) => {
                    newArray[v.key] = v.current
                })
            setWorkflowNameArray(newArray)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])


    function handleOpen() {
        setSelected(workflowName)
        setOpen(true)
    }
    function onCancel() {
        setOpen(false)
        setSelected([])
    }
    const handleSelectValues = (event, value, type) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === Object.entries(keyValuePairObjectNew).length ? [] : Object.entries(keyValuePairObjectNew).map(([key, value]) => key));
        }
        else
            setSelected(newSelected)
    }
    function onAdd() {
        setPutActive(true)
        setWorkflowName(selected)
        setOpen(false)
        setSelected([])
    }
    function handleClose() {
        setOpen(false)
    }
    const handleChange = (fieldName) => {
        setPutActive(true)
        setSelectedRowName(fieldName.attribute_type);
        setWorkflowName(fieldName.key_value.split(','))
        setCurrentID(fieldName.id)
    };
    function handleSubmit(clear) {
        if (clear) {
            props.onSubmit(null, "clear", currentID)
            setWorkflowName([])
            setSelectedRowName('')
            setPutActive(false)
            setCurrentID(0)
        }
        else if (currentID != 0) {
            var data = {
                "key_name": "contract_eligibility_confgiuration",
                "attribute_type": selectedRowName,
                "key_value": workflowName.toString(),
            }
            props.onSubmit(data, "edit", currentID)
            setWorkflowName([])
            setSelectedRowName('')
            setPutActive(false)
            setCurrentID(0)
        }
        else {
            var data = {
                "key_name": "contract_eligibility_confgiuration",
                "attribute_type": selectedRowName,
                "key_value": workflowName.toString(),
            }
            props.onSubmit(data, "create", null)
            setWorkflowName([])
            setSelectedRowName('')
            setPutActive(false)
            setCurrentID(0)
        }

    }
    const handleFieldNameChange = (event) => {
        const selectedAttribute = event.target.value;
        setSelectedRowName(selectedAttribute);
        const matchingItem = listData.find(item => item.attribute_type === selectedAttribute);
        if (matchingItem) {
            setWorkflowName(matchingItem.key_value.split(','));
        } else {
            setWorkflowName([]);
        }
    };



    return (
        <div className={clsx(classes.root, className)}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Mandatory Eligibility Rules</Typography>
            </div>
            <div className={clsx(classes.addRoot, className)} style={{ marginTop: '6px' }}>

                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container} >
                            <Grid container >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <FormLabel error={false} style={{ marginTop: 20 }}>
                                        Attribute Name
                                    </FormLabel>
                                    <Select
                                        value={selectedRowName || ''}
                                        onChange={handleFieldNameChange}
                                        displayEmpty
                                        style={{ width: '100%', marginTop: 0, textTransform: 'capitalize' }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value="" >
                                        </MenuItem>
                                        <MenuItem value="Organization Attributes">Organization Attributes</MenuItem>
                                        <MenuItem value="Customer Attributes">Customer Attributes</MenuItem>
                                        <MenuItem value="Transaction Attributes">Transaction Attributes</MenuItem>
                                        <MenuItem value="Material Attributes">Material Attributes</MenuItem>
                                    </Select>
                                </Grid>
                                {selectedRowName &&
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        style={{ paddingLeft: 15, marginTop: 10 }}
                                    >
                                        <FormLabel error={false}>
                                            Attribute Value
                                        </FormLabel>
                                        <div>
                                            <Select
                                                multiple
                                                input={<OutlinedInput />}
                                                value={workflowName}
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                //onChange={(event, index) => handleSecondApiDropDownValues(event, index)}
                                                style={{ width: '100%', marginTop: 8, textTransform: 'capitalize', paddingLeft: 6, height: 40 }}
                                                onOpen={() => handleOpen()}
                                                onClose={handleClose}
                                                open={open}
                                                renderValue={(selected) => selected.map(key => keyValuePairObjectNew[key]).join(', ')}
                                            >
                                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')}>
                                                    <Checkbox color='primary' checked={isAllSelected} />
                                                    <ListItemText primary='Select All' />
                                                </MenuItem>
                                                {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 ?
                                                    Object.entries(keyValuePairObjectNew)
                                                        .sort()
                                                        .map(([key, value]) => {
                                                            return (
                                                                <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, key)}>
                                                                    <Checkbox
                                                                        color='primary'
                                                                        checked={selected.indexOf(key) > -1} />
                                                                    <ListItemText primary={value} />
                                                                </MenuItem>
                                                            )
                                                        })
                                                    :
                                                    {/* tempArray && tempArray.map((item) => {
                                                            return (
                                                                <MenuItem>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        }) */}
                                                }
                                                <div className={classes.dropdownAction}>
                                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('identifier')}>
                                                        Apply
                                                    </Button>
                                                </div>
                                            </Select>
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.buttonContainer}
                                    style={{ paddingLeft: 10, marginTop: 50 }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ padding: 5 }}
                                        startIcon={<CheckIcon />}
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        disabled={!putActive}
                                        onClick={() => handleSubmit()}
                                    > Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                        startIcon={<CheckIcon />}
                                        disabled={!putActive}
                                        onClick={() => handleSubmit('clear')}
                                    >Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <div
                className={clsx(classes.tableRoot, className)}>
                < Card >
                    <div className={classes.row} >
                        <CardHeader
                            title="EXISTING VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ width: '40%' }}>Attribute Name</TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }}>Attribute Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }}>
                                            {
                                                listData
                                                    // .filter(item => item.attribute_type)
                                                    .map((item, index) => (
                                                        <StyledTableRow
                                                            key={index}
                                                            style={{ cursor: 'pointer' }}
                                                            // onClick={() => handleChange(item.key_value.toLowerCase().replace(' ', '_'))}
                                                            onClick={() => handleChange(item)}
                                                        >
                                                            <TableCell classes={{ root: classes.leftAlign }}>
                                                                {item.attribute_type}
                                                            </TableCell>
                                                            <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                {/* {item.key_value.split(',')
                                                                    .map(item => item.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())).join(', ')} */}
                                                                {item.key_value
                                                                    .split(',')
                                                                    .map(value =>
                                                                        // Use the label from setKeyValuePairObjectNew instead of processing the key directly
                                                                        keyValuePairObjectNew[value.trim()] ||
                                                                        value.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())
                                                                    )
                                                                    .join(', ')}
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        claimData: state.addMultipleConfigurationData.mantrEligRulesData,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id) => dispatch(createManEligRulesDesigner(data, type, id)),
        getClaimData: () => dispatch(getManEligRulesDesigner()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MandatoryEligibilityRules);