import React, { useEffect } from 'react';
import {
    Typography, Switch, Table, TableCell,
    TableHead, TableRow, TableBody, TextField,
    InputAdornment, IconButton, Button,
    Tooltip
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import SearchIcon from '@mui/icons-material/Search';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import { getBudgetingConfig, getPromoStickers, createStickers, getMaterialFilters } from '../../../redux/actions';
import { makeStyles } from '@mui/styles';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from 'react-spinners/HashLoader';
import { SelectedStickers, StickerBankContainer, StickerTableHeader } from './Components';
import moment from 'moment';
import { History, ViewListOutlined } from '@mui/icons-material';
import { getStickerArchiveList } from '../../../redux/actions/Promotion/AuthAction';
import '../Styles/global.css';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 16px',
    },

    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: 10,
        padding: 8,
        //backgroundColor: '#E8E8E8'
    },

    divContainerLeft: {
        position: 'relative',
        //width: '80%',
        height: '100vh',
        // marginLeft: 5,
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '100vh',
        marginLeft: 7,
    },

    mainHeader: {
        marginLeft: '5px',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        // backgroundColor: '#E8E8E8',
        border: '1px solid #E8E8E8',
        borderRadius: 2,
    },

    headerLeft: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    toggleButton: {
        marginTop: 22,
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },

    icons: {
        display: 'flex',
        justifyContent: 'space-around',
    },

    name: {
        display: 'flex',
    },

    filter: {
        display: 'flex',
        justifyContent: 'space-around',
        height: 50,
        border: '1px solid #EEEEEE',
        borderRadius: 8,
        marginTop: 15,
        marginBottom: 15,
    },

    addIcon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: '50%',
        padding: '9px',
        position: 'absolute',
        bottom: '50px',
        right: '50px',
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        fontSize: 60,
    },

    shareIcon: {
        position: 'absolute',
        bottom: '56px',
        right: '140px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    nextIcon: {
        position: 'absolute',
        bottom: '10px',
        right: '122px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    historyButton: {
        cursor: 'pointer',
        border: '1px solid #D8D8D8',
        margin: '-3px 10px',
        padding: 5,
        width: 35,
        height: 35,
    },

    tabHead: {
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
    },

    toggleBtnContainer: {
        border: '0px',
        '&:hover': {
            color: 'black',
        },
    },

    actionIcon: {
        cursor: 'pointer',
    }


}));


const StickerBank = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { className } = props;
    const passedCountry = location.data && location.data.country;
    const passedProductLine = location.data && location.data.productLine;
    const isVisited = location.data && location.data.isVisited;
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [stickersBankData, setStickersBankData] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [archive, setArchive] = React.useState(false);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    useEffect(() => {
        props.getFilters();
    }, []);
    useEffect(() => {
        if (passedCountry && passedProductLine && passedProductLine.length > 0) {
            setProductType(passedProductLine[0])
            setSelectedCountry(passedCountry)
        }
    }, [props.filterData]);

    useEffect(() => {
        if (productType && selectedCountry) {
            if (archive)
                props.getArchive(productType, selectedCountry)
            else
                props.getStickers(productType, selectedCountry);
        }
    }, [productType, selectedCountry, archive]);

    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setProductLinesList(props.filterData['material_master'][0]['material_group'])
            if (props.filterData['material_master'][0]['material_group'][0] && !isVisited) {
                setProductType(props.filterData['material_master'][0]['material_group'][0])
                setSelectedCountry('USA')
            }
        }
    }, [props.filterData]);

    useEffect(() => {
        if (props.stickersData && props.stickersData.records) {
            setStickersBankData(props.stickersData.records);
        }
    }, [props.stickersData]);


    function handleChange(e) {
        setProductType(e.target.value);
    }

    function handleArchiveList() {
        setArchive(!archive)
    }


    function handleSelectedCountry(e) {
        // const country = e == 'CA' ? 'USA' : 'CANADA';
        console.log(e)
        setSelectedCountry(e);
    }

    function handleAdd() {
        history.push({
            pathname: '/promotion-sticker-setup/add',
            data: { variable1: selectedCountry, variable2: Array.isArray(productType) ? productType : [productType], page: 'sticker_bank' }
        });
    }


    return (
        <>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}

            >
                <div className={classes.root}>
                    <StickerTableHeader country={selectedCountry} onCountryChange={handleSelectedCountry} productLineData={productLinesList} defaultDivison={productType} onChange={handleChange} stickerName={""} />

                    <div style={{ height: 50, marginLeft: 5, border: '1px solid #E8E8E8' }}>
                        <div className={classes.row}>
                            <div style={{ display: 'flex' }}>
                                <Button style={{ marginLeft: 10, marginRight: 20 }} className='return-button promo-font' variant="outlined" onClick={() => history.push('/promotion-dashboard')}
                                    startIcon={<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none" className='arrowLeft-icon'>
                                        <path d="M5.24355 1.77083L2.01022 5.00417L5.24355 8.2375C5.56855 8.5625 5.56855 9.0875 5.24355 9.4125C4.91855 9.7375 4.39355 9.7375 4.06855 9.4125L0.243554 5.5875C-0.0814459 5.2625 -0.0814459 4.7375 0.243554 4.4125L4.06855 0.5875C4.39355 0.2625 4.91855 0.2625 5.24355 0.5875C5.56022 0.9125 5.56855 1.44583 5.24355 1.77083Z" fill="#4E4E4E" />
                                    </svg>}
                                >
                                    Return
                                </Button>
                                <Typography color='grey'>STICKER BANK</Typography>
                            </div>
                            {/* <Typography style={{ fontSize: 13, marginTop: 3 }}>
                                <CircleIcon style={{ marginLeft: 15, height: 10, color: 'red' }}></CircleIcon>
                                In Progress
                            </Typography> */}
                            <div>
                                <Button variant="contained" style={{ height: 33, right: 20 }} onClick={handleAdd}>
                                    Create Sticker
                                </Button>
                                {!archive && <Tooltip title='Archive'><IconButton onClick={handleArchiveList}><History className={classes.historyButton} /></IconButton></Tooltip>}
                                {archive && <Tooltip title='List'><IconButton onClick={handleArchiveList}><ViewListOutlined className={classes.historyButton} /></IconButton></Tooltip>}
                            </div>
                        </div>
                        <div className={classes.row} style={{ width: 450, marginRight: 100 }}>
                            {/* <TextField
                                placeholder={"Search Stickers"}
                                onChange={""}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ align: 'center' }}
                            /> */}
                        </div>

                    </div>

                    <div /*style={{ display: 'flex' }}*/>
                        <div className={classes.divContainerLeft}>
                            <StickerBankContainer onChange={handleSelectedCountry} stickerName={"Stickers Bank"}
                                productType={productType} country={selectedCountry}
                                page='sticker_bank'
                                archive={archive} />
                        </div>
                        {/* <div className={classes.divContainerRight}>
                            <SelectedStickers country={selectedCountry} name={'Selected Stickers'} />
                        </div> */}
                    </div>
                </div>
            </LoadingOverlay>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        getStickers: (productType, country, id) => dispatch(getPromoStickers(productType, country, id)),
        getArchive: (productline, country) => dispatch(getStickerArchiveList(productline, country)),
        onAction: (data, type, id) => dispatch(createStickers(data, type, id)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        filterData: state.promotionData.promoFilters,
        stickersData: state.promotionData.promoStickers,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerBank);