import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    IconButton, Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@mui/material';
import { connect } from 'react-redux';
import { getConfigurationDesigner, getWorkFlowAssignments, addWorkFlowAssignments, getLblDispDesFieldValue } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from "react-router-dom";
import { Edit, DeleteForever, LibraryAdd } from '@mui/icons-material';
import { tableIcons } from '../../components/Icons/TableIcons';
import MaterialTable from "material-table";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    container: {
        padding: 10
    },
    fabContainer: {
        marginRight: 5, padding: -1, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    hover: {
        cursor: 'pointer'
    },
}));
const WorkFlowAssignmentsMain = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [workflowName, setWorkflowName] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([]);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [currentDeleteId, setCurrentDeleteId] = React.useState(null);
    const [columns, setColumns] = React.useState([
        {
            field: 'application_type',
            title: 'Application Type',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewCustomer(rowData.ID)}> {rowData.application_type ? rowData.application_type : ''}</a>
        },
        {
            field: 'document_analyst',
            title: 'Document Analyst',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewCustomer(rowData.ID)}> {rowData.document_analyst_details ? rowData.document_analyst_details.userName : ''}</a>
        },
        {
            field: 'document_manager',
            title: 'Document Manager',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewCustomer(rowData.ID)}> {rowData.document_manager_details ? rowData.document_manager_details.userName : ''}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editCustomer(rowData.ID)}
                        size="large">
                        <Edit color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        // onClick={() => deleteData(rowData.ID)}
                        onClick={() => handleDeleteClick(rowData.ID)}
                        size="large">
                        <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                </div>
        },
    ]);
    // const actionColum = [
    //     {
    //         field: 'Actions',
    //         title: 'Actions',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData =>
    //             <div style={{ whiteSpace: 'nowrap' }}>
    //                 <IconButton
    //                     classes={{ root: classes.IconButton }}
    //                     onClick={() => editCustomer(rowData.ID)}
    //                     size="large">
    //                     <Edit color="disabled" style={{ fontSize: 20 }} />
    //                 </IconButton>
    //                 <IconButton
    //                     classes={{ root: classes.IconButton }}
    //                     onClick={() => deleteData(rowData.ID)}
    //                     size="large">
    //                     <DeleteForever color="disabled" style={{ fontSize: 20 }} />
    //                 </IconButton>
    //             </div>
    //     }
    // ]
    useEffect(() => {
        props.getWorkFlowAssignments(1, 0);
        var data = {
            "data_source": "document_header",
            "application_type": 'Customer Rebate',
            "assignment_workflow": true
        }
        props.fieldDesigner(data, 'post');
        props.getConfigurationDesigner();
    }, []);
    useEffect(() => {
        if (props.configdesigner && props.configdesigner.length > 0) {
            props.configdesigner.map(item => {
                if (item.key_name === 'document_assignment_config') {
                    setWorkflowName(item.config_fields)
                }
            })
        }
    }, [props.configdesigner])
    useEffect(() => {
        var newArray = []
        var previousColumns = []
        var newColum = []
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0 && workflowName.length > 0) {
            Object.entries(props.dropdownData.field_label_attributes)
                // .filter(([k, v]) => v.display && v.key !== 'application_type' && v.key !== 'document_analyst')
                .filter(([k, v]) => v.display && workflowName.includes(v.key))
                .map(([k, v]) => {
                    newArray.push(
                        {
                            field: v.key,
                            title: v.current,
                            editable: 'never',
                            type: 'string',
                            render: (rowData) => <a className={classes.hover} onClick={() => viewCustomer(rowData.ID)}> {rowData.filters ? rowData.filters[v.key] : ''}</a>
                        }
                    )
                })
            // newColum = [...newArray, ...columns]
            // setColumns(newColum)
            previousColumns = columns.filter(col => col.field !== 'Actions');
            const newColum = [...previousColumns, ...newArray, {
                field: 'Actions',
                title: 'Actions',
                type: 'string',
                editable: 'never',
                render: rowData =>
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editCustomer(rowData.ID)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            // onClick={() => deleteData(rowData.ID)}
                            onClick={() => handleDeleteClick(rowData.ID)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                    </div>
            }];
        
            setColumns(newColum);
        }
        props.getWorkFlowAssignments(1, 0);
    }, [props.dropdownData && props.dropdownData.field_label_attributes, workflowName])
    function editCustomer(id) {
        history.push({
            pathname: '/workflow-assignment/edit-assignment/' + id,
            id: id
        });
    }
    function viewCustomer(id) {
        history.push({
            pathname: '/workflow-assignment/view-assignment/' + id,
            id: id
        });
    }
    // const deleteData = (id) => {
    //     props.deleteContractDefaultDesigner('', 'delete', id)
    // }

    const handleDeleteClick = (id) => {
        setCurrentDeleteId(id);
        setOpenConfirmDelete(true);
    }

    const handleDeleteConfirm = () => {
        props.deleteContractDefaultDesigner('', 'delete', currentDeleteId);
        setOpenConfirmDelete(false);
        setCurrentDeleteId(null);
    }

    const handleDeleteCancel = () => {
        setOpenConfirmDelete(false);
        setCurrentDeleteId(null);
    }
    useEffect(() => {
        if (props.contractDefaultData && props.contractDefaultData.records && props.contractDefaultData.records.length > 0) {
            setDataRows(props.contractDefaultData.records);
        }
    }, [props.contractDefaultData])
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> Workflow Assignment</Typography>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        //    component={CustomRouterLink}
                        onClick={() => history.push('/workflow-assignment/add-assignment')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>
                </div>
                {props.contractDefaultData && props.contractDefaultData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                ></div>
                            ),
                        }}
                        title={" "}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            search: false,
                            sorting: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            filtering: true,
                            pageSize: 15,
                            pageSizeOptions: [
                                15,
                                20,
                                50,
                                { value: dataRows.length, label: "Show all" },
                            ],
                        }}
                    />

                    :
                    <Typography variant='h4' color='textPrimary' style={{ marginLeft: 16, marginTop: 16 }}>
                        There is no data to show now.
                    </Typography>
                }
                <Dialog
                    open={openConfirmDelete}
                    onClose={handleDeleteCancel}
                >
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this item?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteCancel} variant='outlined' color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteConfirm} variant='contained' color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        contractDefaultData: state.customerData.workFlowAssignmentsGetAll,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        configdesigner: state.addMultipleConfigurationData.configdesigner,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getWorkFlowAssignments: (pagination, limit) => dispatch(getWorkFlowAssignments(pagination, limit)),
        deleteContractDefaultDesigner: (data, type, id) => dispatch(addWorkFlowAssignments(data, type, id)),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getConfigurationDesigner: () => dispatch(getConfigurationDesigner()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkFlowAssignmentsMain);