import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getKeyFigureData, deleteKeyFigure, uploadKeyFigurFile } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import { AddBoxOutlined } from '@mui/icons-material';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import { tableIcons } from '../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    hover: {
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    },
}));
const KeyFigureMapping = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [itemToDelete, setItemToDelete] = React.useState(null);
    const columns = [
        {
            field: 'application_type',
            title: 'Application Type',
            render: (rowData) => <a className={classes.hover} onClick={() => viewKeyFigure(rowData.id)}> {rowData.application_type}</a>
        },
        {
            field: 'source_data_type',
            title: 'Source Data Type',
            render: (rowData) => <a className={classes.hover} onClick={() => viewKeyFigure(rowData.id)}> {rowData.source_data_type}</a>
        },
        // {
        //     field: 'labeled_attribute_name',
        //     title: 'Attribute Name',
        //     render: (rowData) => <a className={classes.hover} onClick={() => viewKeyFigure(rowData.id)}> {rowData.labeled_attribute_name.replace(/_/g, ' ')}</a>
        // },
        // {
        //     field: 'options',
        //     title: 'Option',
        //     render: (rowData) => <a className={classes.hover} onClick={() => viewKeyFigure(rowData.id)}> {rowData.options}</a>
        // },
        // {
        //     field: 'attribute_values',
        //     title: 'Attribute Value',
        //     render: (rowData) => <a className={classes.hover} onClick={() => viewKeyFigure(rowData.id)}> {rowData.attribute_values?.toString()}</a>
        // },
        {
            field: 'labeled_key_figure',
            title: 'Source Key Figure',
            render: (rowData) => <a className={classes.hover} onClick={() => viewKeyFigure(rowData.id)}> {rowData.labeled_key_figure.replace(/_/g, ' ')}</a>
        },
        {
            field: 'labeled_mapped_key_figure',
            title: 'Target Key Figure',
            render: (rowData) => <a className={classes.hover} onClick={() => viewKeyFigure(rowData.id)}> {rowData.labeled_mapped_key_figure.replace(/_/g, ' ')}</a>
        },
        {
            field: 'Action',
            title: 'Actions',
            render: rowData => <>
                <IconButton
                    classes={{ root: classes.IconButton }}
                    onClick={() => editKeyFigure(rowData.id)}
                    size="large">
                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                </IconButton>
                <IconButton
                    classes={{ root: classes.IconButton }}
                    // onClick={() => deleteData(rowData.id)}
                    onClick={() => handleDeleteClick(rowData.id)}
                    size="large">
                    <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                </IconButton>
            </>
        }
    ]
    useEffect(() => {
        props.getKeyFigureData(1, 0);
    }, []);
    useEffect(() => {
        if (props.contractTabledataComplete) {
            setContractsTableComplete(props.contractTabledataComplete);
        }
    }, [props.contractTabledataComplete]);
    useEffect(() => {
        var tempRows = [];
        if (props.keyFigureData && props.keyFigureData.records && props.keyFigureData.records.length > 0)
            props.keyFigureData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        application_type: e.application_type,
                        source_data_type: e.source_data_type,
                        labeled_attribute_name: e.labeled_attribute_name,
                        options: e.options,
                        attribute_values: e.attribute_values,
                        labeled_key_figure: e.labeled_key_figure,
                        labeled_mapped_key_figure: e.labeled_mapped_key_figure,
                    });
                })
        setCount(tempRows.length)
        setDataRows(tempRows);
    }, [props.keyFigureData])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getKeyFigureData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        // console.log(event.target.value)
        setRowsPerPage(event.target.value);
        props.getKeyFigureData(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    function runQuery() {
        // setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadKeyFigurFile(data);
    }
    function editKeyFigure(id) {
        // console.log(id)
        history.push({
            pathname: '/key-figure-mapping/edit-key-figure/' + id,
            id: id
        });
    }

    function viewKeyFigure(id) {
        history.push({
            pathname: '/key-figure-mapping/view-key-figure/' + id,
            id: id
        });
    }
    // const deleteData = (id) => {
    //     props.deleteKeyFigure(id);
    // }

    const handleDeleteClick = (id) => {
        setItemToDelete(id);
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        if (itemToDelete) {
            props.deleteKeyFigure(itemToDelete);
            setItemToDelete(null);
        }
        setOpenDialog(false);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Key Figure Mapping </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >

                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            //    component={CustomRouterLink}
                            onClick={() => history.push('/key-figure-mapping/add-key-figure')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            onClick={() => runQuery()}
                        >
                            <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                        </Fab>
                        <input type='file'
                            accept=".xlsx, .xls, .csv"
                            id='file' ref={inputFile} style={{ display: 'none' }}
                            onChange={(e) => handleDrop(e)} />
                    </div>
                </div>
                {props.keyFigureData && props.keyFigureData.records && props.keyFigureData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            search: false,
                            filtering: true,
                            paging: false,
                            //  pageSize: rowsPerPage,
                            //  count:count,
                            //  page:page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            //  pageSize: 15,
                            //  pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    // style={{ marginTop: -15 }}
                    /> :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this item?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleConfirmDelete()} variant='contained' color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        keyFigureData: state.AddKeyFigureData.keyFigureData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getKeyFigureData: (pagination, limit) => dispatch(getKeyFigureData(pagination, limit)),
        deleteKeyFigure: (id) => dispatch(deleteKeyFigure(id)),
        uploadKeyFigurFile: (file) => dispatch(uploadKeyFigurFile(file))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(KeyFigureMapping);