import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Select, MenuItem, Button, OutlinedInput,
    FormControl, Input, ListItemText,
    FormLabel, TextField, Checkbox,
} from '@mui/material';
import { Grid } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    select: {
        width: '100%',
        // marginTop: 5
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize',
        padding: '4px'
    },
    error: {
        border: '1px solid red !important'
    },
    disabled: {
        color: 'black !important'
    },
    menuPaper: {
        maxHeight: 300
    },
    dropdownAction: {
        display: 'flex',
        // justifyContent: 'flex-end',
        paddingTop: 15,
        paddingBottom: 15,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        // marginRight: 25,
        padding: '10px,15px'
    },
}));

const MultiSelectDropdown = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const [data, setData] = React.useState([]);
    const [changedFlag, setChangedFlag] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }

    const reset = () => {
        setData([]);
    };
    useEffect(() => {
        setChangedFlag(0);
    }, []);
    useEffect(() => {
        if (props.data)
            setData(props.data);
    }, [props.data]);
    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: !changedFlag && props.prevalue ? props.prevalue : data
        };
    });
    const handleSelectValues = (event, value) => {
        let newSelected = [];

        if (value === "all") {
            setSelected(selected.length === props.listArray.length ? [] : props.listArray);
            setAllSelect(selected.length === props.listArray.length ? false : true)

        }
        else {
            const selectedIndex = selected.indexOf(value);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, value);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
            setSelected(newSelected)
            setAllSelect(newSelected.length === props.listArray.length ? true : false)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setData(selected)
        props.onChange(selected)
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
        props.onChange(data)
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(data)
        setData(data)
        setAllSelect(props.listArray && props.listArray.length > 0 && data.length === props.listArray.length)

    }
    return (
        <div>

            <Grid container className={classes.root}>
                <Grid
                    item
                    style={{
                        width: '100%',
                    }}
                >
                    {props.heading &&
                        <FormLabel {...props} error={false} disabled={false} className={props.className}>
                            {props.heading ? props.heading : ''}
                        </FormLabel>
                    }
                    <FormControl {...props}>

                        <Select
                            labelId="demo-mutiple-name-label"
                            id={props.id}
                            multiple
                            value={data}
                            style={{ width: '100%', marginTop: 5, textTransform: props.capitalize ? 'capitalize' : 'none', backgroundColor: props.bgColor ? 'white' : props.brpColor ? '#f5f5f5' : 'none' }}
                            input={<OutlinedInput sx={{ padding: '4px' }} />}
                            MenuProps={{ ...MenuProps, autoFocus: true }}
                            onOpen={() => handleOpen()}
                            onClose={() => setSelectOpen(false)}
                            open={selectOpen}
                            renderValue={(appType) => (
                                appType && appType.length > 0 && appType.map((item, i) => (i != 0 ? "," : "") + item.toString().replace(/_/g, ' '))
                            )}
                            className={props.class ? props.class : classes.multiSelect}
                            classes={{
                                selectMenu: props.class
                            }}
                        >
                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                <Checkbox
                                    color='primary'
                                    checked={allSelect} />
                                <ListItemText primary='Select All' />
                            </MenuItem>
                            {props.listArray && props.listArray.length > 0 && props.listArray
                                .map(item => {
                                    return (
                                        <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={selected.indexOf(item) > -1} />
                                            <ListItemText primary={item.includes('_') ? item.replace(/_/g, ' ') : item} />
                                        </MenuItem>
                                    );
                                })}
                            <MenuItem value=' ' disabled></MenuItem>
                            <div className={classes.dropdownAction} style={{ justifyContent: props.btn ? 'space-evenly' : 'flex-end' }}>
                                <Button variant="outlined" color="primary" className={classes.btn} style={{ marginRight: props.btn ? 0 : 25 }} onClick={onCancel}>
                                    Cancel
                                </Button>

                                <Button variant="contained" color="primary" className={classes.btn} style={{ marginRight: props.btn ? 0 : 25 }} onClick={onAdd} >
                                    Apply
                                </Button>
                            </div>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid >

        </div >
    );

});

export default MultiSelectDropdown;