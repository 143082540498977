import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button,
    IconButton, Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import {
    getCustomerMasterXRefData, addCustomerMasterXRefUser,
    deleteCustomerMasterXRefUser, editCustomerMasterXRefUser, getAllAccrualDesigner, deleteAccrualDesigner
} from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { Toolbar } from './components';
import { useHistory } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HashLoader from 'react-spinners/HashLoader'
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    editIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: '#FE642E',
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
    },
    container: {
        padding: 10
    },
    spinner: {
        height: '100vh'
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const PaymentDesignerMain = props => {
    useEffect(() => {
        props.getAllPaymentDesigner(1, 100);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [editMode, setEditMode] = React.useState(false);
    const [currentEditableId, setCurrentEditableId] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAllPaymentDesigner(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getAllPaymentDesigner(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.customerMasterXRefData) {
            setContractsTableComplete(props.customerMasterXRefData);
        }
    }, [props.customerMasterXRefData]);

    useEffect(() => {
        if (props.approvalData)
            setApprovalData(props.approvalData)
    }, [props.approvalData]);

    function editCustomer(id) {
        history.push({
            pathname: '/accrual-designer/edit-accrual/' + id,
            id: id
        });
    }
    function viewCustomer(id) {
        history.push({
            pathname: '/accrual-designer/view-accrual/' + id,
            id: id
        });
    }

    //add customer
    const [organizationId, setOrganizationId] = React.useState('');
    const [channelPartnerType, setChannelPartnerType] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerEndCustomerId, setChannelPartnerEndCustomerId] = React.useState('');
    const [streetAddress, setStreetAddress] = React.useState('');
    const [state, setState] = React.useState('');
    const [zipCode, setZipCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [userStore, setUserStore] = React.useState([]);
    const [city, setCity] = React.useState('');
    const [approvalData, setApprovalData] = React.useState([]);

    function handleChannelPartnerType(event) {
        setChannelPartnerType(event.target.value);
    }
    function handleChannelPartnerId(event) {
        setChannelPartnerId(event.target.value);
    }
    function handleChannelPartnerEndCustomerId(event) {
        setChannelPartnerEndCustomerId(event.target.value);
    }
    function handleStreetAddress(event) {
        setStreetAddress(event.target.value);
    }
    function handleState(event) {
        setState(event.target.value);
    }
    function handleZipCode(event) {
        setZipCode(event.target.value);
    }
    function handleCountry(event) {
        setCountry(event.target.value);
    }
    function handleCity(event) {
        setCity(event.target.value);
    }
    function handleSubmit() {
        var data = {
            "organization_id": organizationId,
            "channel_partner_type": channelPartnerType,
            "channel_partner_id": channelPartnerId,
            "channel_partner_end_customer_id": channelPartnerEndCustomerId,
            "street_address": streetAddress,
            "state": state,
            "zipcode": zipCode,
            "country": country,
            "city": city
        };
        setUserStore([...userStore, data]);
        if (editMode) {
            props.onEdit(data, currentEditableId);
            setEditMode(false);
        } else {
            props.onSubmit(data);
        }
        handleClear();
    }
    function handleClear() {
        setEditMode(false);
        setOrganizationId('');
        setChannelPartnerType('');
        setChannelPartnerId('');
        setChannelPartnerEndCustomerId('');
        setStreetAddress('');
        setCity('');
        setState('');
        setZipCode('');
        setCountry('');
    }
    // function handleDelete(id) {
    //     props.onDelete(id);
    // }

    const handleDelete = (id) => {
        setDeleteId(id);
        setDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        props.deleteAccrualDesigner(deleteId);
        setDialogOpen(false);
    };

    const handleCancelDelete = () => {
        setDialogOpen(false);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}

                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Accrual Designer</Typography>
                    <Toolbar />
                </div>
                {approvalData && approvalData.total_record > 0 ?
                    <TableContainer style={{ marginTop: -47 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow>
                                    <TableCell align='center' className={classes.tabHead}>Application Types</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Primary Anchor</TableCell>
                                    {/* <TableCell align='center' className={classes.tabHead}>Accrual Level</TableCell> */}
                                    {/* <TableCell align='center' className={classes.tabHead}>Target System</TableCell> */}
                                    <TableCell align='center' className={classes.tabHead}>Document Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}> Reversal Document Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Document Date</TableCell>
                                    {/* <TableCell align='center' className={classes.tabHead}>Tier Projection</TableCell> */}
                                    <TableCell align='center' className={classes.tabHead}>Reference Number</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Assignment Number</TableCell>
                                    {/* <TableCell align='center' className={classes.tabHead}>Accrual Aggregation</TableCell> */}
                                    {/* <TableCell align='center' className={classes.tabHead}>Credit Account</TableCell> */}
                                    {/* <TableCell align='center' className={classes.tabHead}>Debit Account</TableCell> */}
                                    {/* <TableCell align='center' className={classes.tabHead}>Credit Profit Center</TableCell> */}
                                    {/* <TableCell align='center' className={classes.tabHead}>Debit Profit Center</TableCell> */}
                                    <TableCell align='center' className={classes.tabHead} style={{ width: 62 }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {approvalData.records
                                    .sort((a, b) => a.ID < b.ID ? 1 : -1)
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.contract_number}>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.app_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.primary_anchor?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}</TableCell>
                                                {/* <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.accrual_level}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.target_system}</TableCell> */}
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.document_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.reversal_document_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.document_date}</TableCell>
                                                {/* <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.tier_projection}</TableCell> */}
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.reference_number?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.assignment_number?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}</TableCell>
                                                {/* <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.accrual_aggregation}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.credit_account}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.debit_account}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.credit_profit_center}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.debit_profit_center}</TableCell> */}

                                                <TableCell align='center'>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editCustomer(item.ID)}
                                                            size="large">
                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                        <IconButton
                                                            // onClick={() => props.deleteAccrualDesigner(item.ID)}
                                                            onClick={() => handleDelete(item.ID)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                            component="div"
                            count={props.approvalData && props.approvalData.records ? props.approvalData.total_record: 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        /> */}
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog open={dialogOpen} onClose={handleCancelDelete}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    Are you sure you want to delete this item?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant='contained' color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        approvalData: state.customerData.accrualDesignerGetAll
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCustomerMasterXRefData: (pagination, limit) => dispatch(getCustomerMasterXRefData(pagination, limit)),
        onSubmit: (data) => dispatch(addCustomerMasterXRefUser(data)),
        deleteAccrualDesigner: (id) => dispatch(deleteAccrualDesigner(id)),
        onEdit: (data, id) => dispatch(editCustomerMasterXRefUser(data, id)),
        getAllPaymentDesigner: (pagination, limit) => dispatch(getAllAccrualDesigner(pagination, limit)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDesignerMain);