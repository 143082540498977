import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, Button, IconButton,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody, TablePagination, Dialog, DialogTitle, DialogContent, DialogActions, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Edit, DeleteForever, LibraryAdd, AddBoxOutlined } from '@mui/icons-material';
import { getQueryasList, getQueryDetails, submitQuery } from '../../redux/actions';
import secureLocalStorage from 'react-secure-storage';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));
const QueryFieldMain = props => {

    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [apiResponse2, setApiResponse2] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [itemToDelete, setItemToDelete] = React.useState(null);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        props.getQueryasList(1, 100);
    }, []);
    useEffect(() => {
        if (props.queryListData && props.queryListData.length > 0) {
            setApiResponse2(props.queryListData);
        }
    }, [props.queryListData]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getQueryasList(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getQueryasList(1, event.target.value);
    };

    const handleEdit = (item) => {
        history.push({
            pathname: '/query-field/edit-query-field/' + item.ID,
            id: item.ID
        })
        props.getQueryDetails(item.ID);
        localStorage.setItem('query-details', JSON.stringify(item));
    }
    function viewQuery(item) {
        localStorage.setItem('query-details', JSON.stringify(item))
        history.push({
            pathname: '/query-field/view-query-field/' + item.ID,
            id: item.ID
        });
    }
    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setOpen(true);
    };

    const handleConfirmDelete = () => {
        if (itemToDelete) {
            props.deleteQuery(itemToDelete, itemToDelete.ID);
        }
        setOpen(false);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };


    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Query Field</Typography>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights &&

                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        //    component={CustomRouterLink}
                        onClick={() => history.push('/query-field/add-query-field')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>}
            </div>
            {props.queryListData && props.queryListData.length > 0 ?
                <TableContainer style={{ marginTop: -25 }}>
                    <Table stickyHeader>
                        <TableHead >
                            <TableRow >

                                <TableCell align='center' className={classes.tabHead}>Name</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Description</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Query</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {apiResponse2

                                .map(item => {
                                    return (
                                        <StyledTableRow key={item.ID} >

                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewQuery(item)} style={{ cursor: 'pointer' }}>{item.Name}</TableCell>

                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewQuery(item)} style={{ cursor: 'pointer' }}>{item.Description}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewQuery(item)} style={{ cursor: 'pointer' }}>{item.Query}</TableCell>
                                            <TableCell align='center'>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights &&
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => handleEdit(item)}
                                                            size="large">
                                                            <Edit color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>}
                                                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights &&
                                                        <IconButton
                                                            // onClick={() => props.deleteQuery(item, item.ID)}
                                                            onClick={() => handleDeleteClick(item)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <DeleteForever />
                                                        </IconButton>}
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>

                    {/* <TablePagination
                        component="div"
                        count={props.queryListData && props.queryListData.length ? props.queryListData.length : 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={handleChangePage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        classes={{
                            caption: classes.caption,
                            root: classes.paginationRoot
                        }}
                    /> */}

                </TableContainer>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>
            }
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this item?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant='contained' color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.QueryFieldData.queryListData,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getQueryasList: (pagination, limit) => dispatch(getQueryasList(pagination, limit)),
        getQueryDetails: (id) => dispatch(getQueryDetails(id)),
        deleteQuery: (data, id) => dispatch(submitQuery(data, id, 'delete'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryFieldMain);