import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const DesignerStudio = (props) => {
    const handleClick = (pge) => {
        history.push(pge)
    }

    const application = sessionStorage.getItem("application");
    const hasRight = (right) => {
        const user = secureLocalStorage.getItem("dYtz");
        return (
            user &&
            user.role &&
            user.role.rights &&
            user.role.rights.includes(`${right}-${application}`)
        );
    };
    const hasPredefinedRight = hasRight("predefined_query_designer");

    const menuItems = [

        {
            'Contract Designer': [
                // { 'Dropdown Designer': '/multiple-configuration' },
                // { 'Display Designer': '/field-display-configuration' },
                { 'Eligibility Designer': '/eligibility-designer' },
                { 'Calculation Designer': '/keyfigure-configuration' },
                { 'Tier Evaluation Designer': '/tier-evaluation-level-designer' },
                { 'Default Designer': '/contract-default-designer' },
                { "Contract Attributes": "/contract-attributes" },
                { "Mandatory Eligibility Rules": "/mandatory-eligibility-rules" },
                // { '': '/' },
            ]
        },
        {
            'Calculation Designer': [
                { 'Global Eligibility': '/sales-basis' },
                { 'Key Figure Mapping': '/key-figure-mapping' },
                { 'Attribute Mapping': '/attribute-mapping' },
                { 'Basis Mapping': '/basis-mapping' },
                { 'Calculation Analysis': '/calculation-analysis' },
                // { '': '/' },
            ]
        },
        // {
        //     'Claim Designer': [
        //         { 'Configuration': '/claim-configuration' },
        //         { 'Rejection Reasons': '/rejection-reasons' },
        //         // { '': '/' },
        //     ]
        // },
        {
            'Postings Designer': [
                { 'Accrual Designer': '/accrual-designer' },
                { 'Payment Designer': '/payment-designer' },
                { 'Holiday Calendar': '/holiday-calendar' },
                { 'Financial Close': '/financial-close' },
                { 'Fiscal Year Definition': '/fiscal-year-definition' },
                { 'Statement Defaults': '/org-defaults' },
                // { '': '/' },
            ]
        },
        {
            'Approval Designer': [
                { 'Approval Groups': '/approval-group' },
                { 'Approval Levels': '/approval-limits' },
                { 'Approval Workflow': '/document-workflow' },
                { 'Workflow Assignment': '/workflow-assignment' },
            ]
        },
        {
            'Analytics Designer': [
                { 'Analytics Designer': '/analytics-designer' },
                { 'Dashboard Designer': '/dashboard-designer' },
                { "Process Reporting Designer": "/reporting-by-function" },
                ...(hasPredefinedRight ? [{ 'Predefined Queries': '/query-field' }] : []),
                // { 'Predefined Queries': '/query-field' },
            ]
        },
        {
            'UI Designer': '/label-display-designer'
        },
        {
            'Configuration': '/config-designer'
        }

    ]

    // const DesignerStudio = (props) => {

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }} >
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};
const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignerStudio);