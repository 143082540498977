import React, { useEffect } from 'react';
import {
    Button, ToggleButton, ToggleButtonGroup, Accordion, AccordionSummary, AccordionDetails, Typography,
    Divider
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { CountertopsOutlined, ExpandCircleDownOutlined, ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1,
        marginBottom: 25,
    },
    menuHead: {
        display: 'flex',
        flexDirection: 'row'
    },
    menuDetail: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    container: {
        marginTop: 15,
    },
    listItem: {
        width: '70%'
    },
    accordion: {
        marginRight: 3, border: '0.8px solid #E2E2E2',
        marginBottom: 10
    },
    accordionSelected: {
        marginRight: 3, border: '0.8px solid',
        borderColor: theme.palette.primary.main,
        marginBottom: 10
    },
    accordionHeader: {
        display: 'flex',
        flexDirection: 'column',
    },

}))

const ReviewBox = (props) => {

    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const [reviewType, setReviewType] = React.useState('pending');
    const [select, setSelect] = React.useState(0);
    const [selectedStates, setSelectedStates] = React.useState([]);
    const [validate, setValidate] = React.useState([]);
    const [formattedStates, setFormattedStates] = React.useState([]);
    const [stickerIDs, setStickerIDs] = React.useState([]);
    const [selectedMarket, setSelectedMarket] = React.useState([]);
    const [pendingData, setPendingData] = React.useState({});
    const [completedData, setCompletedData] = React.useState({});
    const [expandedAccordion, setExpandedAccordion] = React.useState({});
    const [countSticker, setCountSticker] = React.useState(0);


    useEffect(() => {
        if (props.pendingData)
            setPendingData(props.pendingData)
    }, [props.pendingData]);

    useEffect(() => {
        if (props.completedData)
            setCompletedData(props.completedData)
    }, [props.completedData]);

    useEffect(() => {
        var data
        data = reviewType === 'pending' ? pendingData : completedData
        const content = renderContent(data);
        setCountSticker(content.length);
        props.reviewStatus(content.length)
    }, [pendingData, completedData]);

    // const handleAccordionToggle = (key, index, id) => (event, isExpanded) => {
    //     if (isExpanded) {
    //         props.onSet(id);
    //         setExpandedAccordion(`${key}-${index}`);
    //     } else {
    //         setExpandedAccordion(null);
    //     }
    // };

    function handleReview(id, label) {
        props.review(id, label);
        setSelect(0)
    }

    const handlefilter = (event, key) => {
        event.stopPropagation();
        // setSelect(item.sticker_id)
        if (!selectedMarket.includes(key))
            setSelectedMarket([...selectedMarket, key])
        else
            setSelectedMarket(selectedMarket.filter(item => item != key))

        // props.onSet(item.sticker_id)
    }

    const handleAccordionClick = (event, id) => {
        event.stopPropagation()
        if (id != select)
            props.onSet(id)
        setSelect(id);
    };

    function renderContent(data) {
        const renderAccordion = (key, item, index) => {
            const isExpanded = selectedMarket && selectedMarket.includes(item.sticker_label);

            return (
                <Accordion
                    key={`${key}-${index}`}
                    className={select === item.sticker_id ? classes.accordionSelected : classes.accordion}
                    // expanded={isExpanded}
                    // onChange={handleAccordionToggle(key, index, item.sticker_id)}
                    // onChange={() => handlefilter(item, item.sticker_label)}
                    expanded={selectedMarket && selectedMarket.includes(item.sticker_label)}
                    onChange={(event) => handleAccordionClick(event, item.sticker_id)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlined color='primary' onClick={(event) => handlefilter(event, item.sticker_label)} />}
                        aria-controls={`panel1a-content-${key}-${index}`}
                        id={`panel1a-header-${key}-${index}`}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className={classes.menuHead}>
                                <Typography variant='h5' className={classes.heading}>
                                    {key.toUpperCase().replace('_', ' ')}
                                </Typography>
                                <Typography variant='h5' color={'black'} className={classes.heading}>
                                    {'- '}{item.sticker_name}
                                </Typography>
                            </div>
                            <Typography variant='h3' color={'black'} className={classes.heading}>
                                {item.material_groups && item.material_groups.length > 0 && !isExpanded &&
                                    item.material_groups
                                        .map(group => `MY${group.material_group1 ? group.material_group1.slice(-2) : ''}`)
                                        .join(', ')
                                }
                            </Typography>
                        </div>
                    </AccordionSummary>
                    {item.material_groups && item.material_groups.length > 0 ? (
                        item.material_groups.map((group, groupIndex) => (
                            <AccordionDetails key={`${key}-${index}-group-${groupIndex}`}>
                                <div className={classes.menuHead}>
                                    <div style={{ width: '75%' }}>
                                        <Typography variant='h3' color={'black'}>
                                            MY{group.material_group1 && group.material_group1.slice(-2)}
                                        </Typography>
                                        <Typography variant='h5' color={'grey'}>
                                            {group.material_group4 && group.material_group4.join(', ')}
                                        </Typography>
                                    </div>
                                    <div style={{ width: '25%' }}>
                                        <Typography variant='h5' color={'black'}>
                                            {group.material_group4 && group.material_group4.length}{' Models'}
                                        </Typography>
                                    </div>
                                </div>
                            </AccordionDetails>
                        ))
                    ) : (
                        <AccordionDetails>
                            <Typography align='center' variant='h5' color={'grey'}>
                                There is no model for the sticker.
                            </Typography>
                        </AccordionDetails>
                    )}
                    <hr style={{ margin: 0 }} />
                    {key !== 'completed' &&
                        <div className={classes.buttonContainer}>
                            <Button variant='outlined' disabled={select !== item.sticker_id} style={{ marginTop: 30 }} onClick={() => handleReview(item.sticker_id, item.sticker_label)}>Marked as Reviewed</Button>
                        </div>
                    }
                </Accordion>
            );
        };

        let content = [];

        Object.keys(data).forEach((key) => {
            if (data[key] !== null && Array.isArray(data[key])) {
                data[key].forEach((item, index) => {
                    content.push(renderAccordion(key, item, index));
                });
            }
        });

        return content.length > 0 ? content : (
            <Typography align='center' variant='h5' color={'grey'}>
                No data available.
            </Typography>
        );
    }


    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null)
            setReviewType(newAlignment)

    }

    function handlePending() {
        props.onChange('pending')
    }

    function handleCompleted() {
        props.onChange('completed')
    }
    // console.log(pendingData)
    // console.log(completedData)
    return (
        <div>
            <ToggleButtonGroup
                color="primary"
                variant='contained'
                value={reviewType}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                fullWidth
            >
                <ToggleButton value="pending" onClick={handlePending} variant='contained'>Pending Review</ToggleButton>
                <ToggleButton value="completed" onClick={handleCompleted}>Completed</ToggleButton>
            </ToggleButtonGroup>
            {reviewType == 'pending' &&
                <div className={classes.container}>
                    {renderContent(pendingData)}
                </div>
            }
            {reviewType == 'completed' &&
                <div>
                    <div className={classes.container}>
                        {renderContent(completedData)}
                    </div>
                </div>

            }
        </div>
    )
}
export default ReviewBox
