import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Typography
} from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        zIndex: 1000,
        width: 350,
        marginLeft: -365,
        marginTop: 20
    },
    dropdownContainer: {
        maxHeight: '350px', // Set maximum height
        overflowY: 'auto', // Enable vertical scrolling
        border: "0.8px solid #E2E2E2",
        borderRadius: 4,
        backgroundColor: 'white'
    },
    accordionMenu: {
        padding: 10,
        maxHeight: 30,
        margin: 0,
    },
    accordionHead: {
        padding: 0,
        minHeight: 30,
        margin: "0px !important",
        width: 'fit-content'
    },
    accordionAction: {
        backgroundColor: 'white',
        border: "0.8px solid #E2E2E2",
        margin: '0px 5px'
    },
    accordionRoot: {
        backgroundColor: 'transparent',
        margin: 0,
    },
    muiExpand: {
        minHeight: "30px !important",
        margin: '0px !important'
    }
}));

const PromoSelect = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [checkedData, setCheckedData] = useState('');

    useEffect(() => {
        setExpanded(props.expand);
    }, [props.expand]);

    useEffect(() => {
        setCheckedData(props.prevalue ? props.prevalue : '');
    }, [props.prevalue]);

    const handleCheckboxChange = (item) => (event) => {
        event.stopPropagation(); // Stop event propagation
        setCheckedData(item.scenario_name);
        setExpanded(false);
        if (event.target.checked) {
            props.onSubmit(item.scenario_name, item.scenario_id, item.sticker_data);
        } else {
            props.onSubmit();
        }
    };

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    const renderItems = (items) => {
        return items && items.map((item, index) => (
            <div key={index} style={{ display: 'flex', paddingLeft: 5 }}>
                <Typography variant='h4' color='black'>
                    <Checkbox
                        checked={checkedData === item.scenario_name}
                        onChange={handleCheckboxChange(item)}
                    />
                    {item.scenario_name}
                </Typography>
            </div>
        ));
    };

    const handleCancel = (event) => {
        event.stopPropagation();
        setExpanded(false);
        props.onSubmit(null, 'cancel');
    };

    return (
        <div className={classes.root}>
            {expanded &&
                <Accordion
                    id={props.id}
                    expanded={expanded}
                    onChange={handleAccordionChange}
                    className={classes.accordionRoot}
                >
                    <AccordionSummary
                        className={classes.accordionHead}
                        classes={{
                            expanded: classes.muiExpand, // Override styles when Accordion is expanded
                        }}
                    >
                        <Typography variant='h4' color='grey'>
                            {props.head}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 5 }}>
                        <div className={classes.dropdownContainer}>
                            {renderItems(props.data)}
                        </div>
                    </AccordionDetails>
                </Accordion>
            }
        </div>
    );
};

export default PromoSelect;