import React, { useEffect } from 'react';
import {
    Button, Switch, MenuItem, Select, Checkbox,
    Grid, Typography, TextField, IconButton,
    InputAdornment, CardHeader, Card, FormLabel, OutlinedInput
} from '@mui/material';
import {
    List, ErrorOutlineOutlined, ArrowDropDownTwoTone,
    ArrowDropUpTwoTone,
} from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import { makeStyles } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import clsx from 'clsx';
import { NestedDropdown, NestedDropdown2, PromoMultiSelect, PromoSelect, SingleMultiLevelList } from '../Components';
import { getMaterialFilters, getBudgetingConfig, getModels, getPrefillScenarioList, createPromotionScenario } from '../../../redux/actions';
import { MultiSelectDropdown } from '../../../components/Inputs';
import LoadingOverlay from 'react-loading-overlay';
import { RingLoader } from 'react-spinners';
import { isArray } from 'validate.js';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        // borderRadius: 10,
        //paddingTop: '5px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },

    topHeader: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 70,
        border: '1px solid #E8E8E8',
        borderRadius: 2,
        alignItems: 'center',
    },
    mainHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        marginLeft: '5px',
        height: 135,
        border: '1px solid #E8E8E8',
        borderTop: 0,
        borderRadius: 2,
        backgroundColor: '#f5f5f5',
    },

    headerItem: {
        marginLeft: 35,
        // zIndex: -1
    },
    searchBox: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    searchItem: {
        width: '60%'
    },
    selectBox: {
        // width: 175,
        //height: 30,
        border: "1px #E8E8E8",
        borderRadius: 2,
        backgroundColor: 'white',
        flexDirection: 'row',
        display: 'flex',
    },
    arrowSelectBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '89%',
        border: '1px solid #E0E0E0',
        height: 37,
        padding: 3,
    },
    optionsSelectBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '52%',
        border: '1px solid #E0E0E0',
        height: 33,
        padding: 3,
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        // padding: '5px 25px 0px 10px !important'
    },
    mainToggleButton: {
        marginTop: 10,
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },
    toggleButton: {
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },
    checkBoxFont: {
        fontSize: 14,
    },
    outlined: {
        width: '100%',
        height: '37px'
    },
    prefillOutlined: {
        width: '100%',
        height: '37px',
        border: '0px'
    },
    fontSetup: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '128px',
        textAlign: 'center',
        borderRadius: '2rem',
        height: '36px'
    },

}));

const ScenarioSetting = props => {
    const classes = useStyles();
    const history = useHistory();
    const [requireAmount, setRequireAmount] = React.useState(false);
    const [requireCertificate, setRequireCertificate] = React.useState(false);
    const [promoDivisionArray, setPromoDivisionArray] = React.useState([]);
    const [promoConfigData, setPromoConfigData] = React.useState([]);
    const [promoModelYearArray, setPromoModelYearArray] = React.useState([]);
    const [promoLocation, setPromoLocation] = React.useState();
    const [productLine, setProductLine] = React.useState();
    const [country, setCountry] = React.useState();
    const [modelYear, setModelYear] = React.useState([]);
    const [promoType, setPromoType] = React.useState();
    const currency = ['USD', 'CAD', 'EUR'];
    const countries = ['USA', 'CA'];
    const optionsArray = ['Certificate', 'Authorization Codes', 'Pin Code']
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [expanded, setExpanded] = React.useState([]);
    const [anchorloc, setAnchorloc] = React.useState(false);
    const [promoTheme, setPromoTheme] = React.useState('');
    const [previousScenarios, setPreviousScenarios] = React.useState([]);
    const [scenarios, setScenarios] = React.useState('');
    const [scenariosID, setScenariosID] = React.useState('');
    const [scenarioExpand, setScenarioExpand] = React.useState([]);
    const [anchorE1, setAnchorE1] = React.useState(false);
    const [promoData, setPromoData] = React.useState({});
    const [promotionStartDate, setPromotionStartDate] = React.useState(null);
    const [promotionEndDate, setPromotionEndDate] = React.useState(null);
    const [reservationStartDate, setReservationStartDate] = React.useState(null);
    const [reservationEndDate, setReservationEndDate] = React.useState(null);
    const [invoiceStartDate, setInvoiceStartDate] = React.useState(null);
    const [usageCode, setUsageCode] = React.useState([]);
    const [salesCodeList, setSalesCodeList] = React.useState([]);
    const [validateCodeList, setValidateCodeList] = React.useState([]);
    const [promoStartDateError, setPromoStartDateError] = React.useState('');
    const [promoEndDateError, setPromoEndDateError] = React.useState('');
    const [resStartDateError, setResStartDateError] = React.useState('');
    const [resEndDateError, setResEndDateError] = React.useState('');
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    };


    useEffect(() => {
        const data = {
            table_name: "prefill_scenario"
        }
        props.getScenarioList(data)
        props.getConfigData()
        props.getFilters()
    }, []);

    useEffect(() => {
        if (props.scenarioList)
            setPreviousScenarios(props.scenarioList)

    }, [props.scenarioList]);


    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setPromoModelYearArray(props.filterData['material_master'][0]['material_group1'])
            setPromoDivisionArray(props.filterData['material_master'][0]['material_group'])
        }
    }, [props.filterData]);


    useEffect(() => {
        if (props.configData) {
            setPromoConfigData(props.configData)
        }
    }, [props.configData]);
    useEffect(() => {
        var sale = []
        var code = []
        if (usageCode && usageCode.length > 0 && promoConfigData && promoConfigData[promoType]) {
            usageCode && usageCode.map(item => {
                if (promoConfigData[promoType][item]) {
                    if (promoConfigData[promoType][item]['sales_program_code']) {
                        sale.push(...promoConfigData[promoType][item]['sales_program_code'])
                    }
                    if (promoConfigData[promoType][item]['validation']) {
                        code.push(...promoConfigData[promoType][item]['validation'])
                    }
                }
            })
            setSalesCodeList([...new Set(sale)])
            setValidateCodeList([...new Set(code)])
            if (promoData) {
                var temp = {
                    'sales_program_code': sale && sale.includes(promoData['sales_program_code']) ? promoData['sales_program_code'] : '',
                    'validate_code': code && code.includes(promoData['validate_code']) ? promoData['validate_code'] : ''
                }
                setPromoData(prev => ({
                    ...prev,
                    ...temp
                }))

            }
        }
        else {
            setSalesCodeList([])
            setValidateCodeList([])
        }
    }, [usageCode])

    useEffect(() => {
        if (props.scenario) {
            setPromoData({ ...props.scenario });
            setPromoType(props.scenario.promotion_type)
            setUsageCode(props.scenario.usage_code)
            setCountry(props.scenario.country ? props.scenario.country[0] : '')
            setProductLine(props.scenario.product_lines)
            setModelYear(props.scenario.material_group1)
            setPromoTheme(props.scenario.promotion_theme)
            setPromotionEndDate((props.scenario.promotion_end_date && props.scenario.promotion_end_date !== "0001-01-01T00:00:00Z") ? props.scenario.promotion_end_date : null)
            setPromotionStartDate((props.scenario.promotion_start_date && props.scenario.promotion_start_date !== "0001-01-01T00:00:00Z") ? props.scenario.promotion_start_date : null)
            setReservationStartDate((props.scenario.reservation_start_date && props.scenario.reservation_start_date !== "0001-01-01T00:00:00Z") ? props.scenario.reservation_start_date : null)
            setReservationEndDate((props.scenario.reservation_end_date && props.scenario.reservation_end_date !== "0001-01-01T00:00:00Z") ? props.scenario.reservation_end_date : null)
            setInvoiceStartDate((props.scenario.invoice_start_date && props.scenario.invoice_start_date !== "0001-01-01T00:00:00Z") ? props.scenario.invoice_start_date : null)
            if (props.scenario.promo_location?.location) {
                setSelectedLocItems(props.scenario.promo_location.location)
                setSelectedLoc(props.scenario.promo_location.location_filter)
                handleLocationView(Object.values(props.scenario.promo_location.location), Object.keys(props.scenario.promo_location.location_filter))
            }
            else {
                setPromoLocation([])
                setSelectedLoc([])
                setSelectedLocItems([])
            }
        }

    }, [props.scenario])

    function handleFormData() {
        var formData = {
            ...promoData,
            'country': country,
            'promotion_theme': promoTheme,
            'promotion_start_date': promotionStartDate ? new Date(Moment(promotionStartDate).format('YYYY-MM-DD')).toISOString() : null,
            'promotion_end_date': promotionEndDate ? new Date(Moment(promotionEndDate).format('YYYY-MM-DD')).toISOString() : null,
            'reservation_start_date': reservationStartDate ? new Date(Moment(reservationStartDate).format('YYYY-MM-DD')).toISOString() : null,
            'reservation_end_date': reservationEndDate ? new Date(Moment(reservationEndDate).format('YYYY-MM-DD')).toISOString() : null,
            'invoice_start_date': invoiceStartDate ? new Date(Moment(invoiceStartDate).format('YYYY-MM-DD')).toISOString() : null,
        }
        props.onChange(formData, selectedLocItems)
    }
    function handleSettings(field, value, type) {
        setPromoData(prev => ({
            ...prev,
            [field]: type == //'date' ? value ? new Date(Moment(value).format('YYYY-MM-DD')).toISOString() : null :
                type == 'integer' ? value ? parseFloat(value) : 0 : field == 'promotion_category' ? value == true ? 'Budget' : 'live_promotion' :
                value
        }))
        if (field == 'promotion_type')
            setPromoType(value)
    }
    const handleUsageCode = (value) => {
        if (isArray(value)) {
            setUsageCode(value)
            setPromoData(prev => ({
                ...prev,
                'usage_code': value ? value : [],
            }))
        }

    }
    function handleTheme(e) {
        setPromoTheme(e.target.value)
    }
    const handlePromotionStartDate = (date) => {
        if (date && Moment(date).isValid()) {
            const year = Moment(date).year()
            if (year < 2000) {
                setPromoStartDateError("Invalid Start date. Please use mm/dd/yyyy.")
            }
            else if (promotionEndDate && Moment(date).isAfter(Moment(promotionEndDate))) {
                setPromoStartDateError("Start date can't be after the End date.");
            } else {
                setPromoStartDateError('')
                setPromotionStartDate(date)
            }
        } else {
            if (date)
                setPromoStartDateError("Invalid Start date. Please use mm/dd/yyyy.")
            else {
                setPromoStartDateError('')
                setPromotionStartDate(null)
            }
        }
    };
    const handlePromotionEndDate = (date) => {
        if (date && Moment(date).isValid()) {
            const year = Moment(date).year()
            if (year < 2000) {
                setPromoEndDateError("Invalid Start date. Please use mm/dd/yyyy.")
            }
            else if (promotionStartDate && Moment(date).isBefore(Moment(promotionStartDate))) {
                setPromoEndDateError("End date can't be before the Start date.");
            } else {
                setPromoEndDateError('')
                setPromotionEndDate(date)
            }
        } else {
            if (date)
                setPromoEndDateError("Invalid End date. Please use mm/dd/yyyy.")
            else {
                setPromoEndDateError('')
                setPromotionEndDate(null)
            }
        }
    };
    const handleReservationStartDate = (date) => {
        if (date && Moment(date).isValid()) {
            const year = Moment(date).year()
            if (year < 2000) {
                setResStartDateError("Invalid Start date. Please use mm/dd/yyyy.")
            }
            else if (reservationEndDate && Moment(date).isAfter(Moment(reservationEndDate))) {
                setResStartDateError("Start date can't be after the End date.")
            } else {
                setResStartDateError('')
                setReservationStartDate(date)
            }
        } else {
            if (date)
                setResStartDateError("Invalid Start date. Please use mm/dd/yyyy.")
            else {
                setResStartDateError('')
                setReservationStartDate(null)
            }
        }
    }
    const handleReservationEndDate = (date) => {
        if (date && Moment(date).isValid()) {
            const year = Moment(date).year();
            if (year < 2000) {
                setResEndDateError("Invalid Start date. Please use mm/dd/yyyy.");
            }
            else if (reservationStartDate && Moment(date).isBefore(Moment(reservationStartDate))) {
                setResEndDateError("End date can't be before the Start date.");
            } else {
                setResEndDateError('')
                setReservationEndDate(date)
            }
        } else {
            if (date)
                setResEndDateError("Invalid End date. Please use mm/dd/yyyy.")
            else {
                setResEndDateError('')
                setReservationEndDate(null)
            }
        }
    }
    const handleInvoiceStartDate = (value) => {
        setInvoiceStartDate(value);
    }

    const handleClick = (type) => {
        if (type == 'location') {
            setAnchorloc(!anchorloc)
            const isExpanded = !anchorloc
            setExpanded(isExpanded ? [...expanded, 'loc_filter'] : []);
        }
        else {
            setAnchorE1(!anchorE1)
            const isExpanded = !anchorE1
            setScenarioExpand(isExpanded ? [...scenarioExpand, 'scenario_filter'] : []);
        }
    };

    const handleCheckedLoc = (selectedItems, arr1, type) => {
        console.log(selectedItems)
        console.log(arr1)
        setAnchorloc(false)
        if (type !== 'cancel') {
            setSelectedLocItems(selectedItems)
            setSelectedLoc(arr1)
            setPromoData(prev => ({
                ...prev,
                'promo_location': { 'location': selectedItems, 'location_filter': arr1 }
            }))
            handleLocationView(Object.values(selectedItems), Object.keys(arr1))
        }
    }

    // for dispalying location in field
    function handleLocationView(selectedItems, arr1) {
        console.log(selectedItems)
        console.log(arr1)
        const regions = {};
        const selectedCountry = []
        if (selectedItems && selectedItems.length > 0) {
            if (selectedItems[0]) {
                selectedItems
                    .sort((a, b) => {
                        return countries.indexOf(b.country) - countries.indexOf(a.country);
                    })
                    .forEach(item => {
                        if (arr1.includes(item.country)) {
                            if (!regions[item.country]) {
                                regions[item.country] = new Set();
                                selectedCountry.push(item.country)
                                //setCountry([...country, item.country])
                            }

                        }
                        else {
                            if (item.region) {
                                let element = item.country + "_" + item.region
                                if (!regions[item.country]) {
                                    regions[item.country] = new Set();
                                    //setCountry([...country, item.country])
                                    selectedCountry.push(item.country)
                                }
                                if (arr1.includes(element)) {
                                    regions[item.region] = new Set();
                                }
                                else {
                                    if (!regions[item.region]) {
                                        regions[item.region] = new Set();
                                    }
                                    /*  else {
                                          if (!regions[item.region]) {
                                              regions[item.region] = new Set();
                                          }*/
                                    if (item.state) {
                                        regions[item.region].add(item.state.toUpperCase());
                                    }
                                    // }
                                }
                            }
                        }
                    });
            }
            const selectedLocations = [];
            for (const region in regions) {
                const states = Array.from(regions[region]);
                if (states.length > 0) {
                    const regionString = `${region.toUpperCase()}(${states.join(',')})`;
                    selectedLocations.push(regionString);
                } else {
                    selectedLocations.push(region.toUpperCase());
                }
            }

            const selectedLocationsString = selectedLocations.join(', ');
            setPromoLocation(selectedLocationsString)
            setCountry(selectedCountry)

        }
        else {
            setPromoLocation([])
            setCountry([])
        }
    }
    const handleProductLine = (value) => {
        if (isArray(value)) {
            setProductLine(value)
            setPromoData(prev => ({
                ...prev,
                'product_lines': value ? value : []
            }))
        }
    }

    const handleModelYear = (value) => {
        if (value && value.length > 0)
            setModelYear(value)
        setPromoData(prev => ({
            ...prev,
            'material_group1': value ? value : []
        }))
    }

    const handleScenario = (checked, id) => {
        if (id) {
            setScenarios(checked)
            setScenariosID(id)
        }
        else {
            setScenarios('')
            setScenariosID('')
        }
        setScenarioExpand([])
        setAnchorE1(false)
        props.handlePrefill(id)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "30px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<RingLoader />}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <div className={classes.topHeader}>
                    <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between' }}>
                        <div className={classes.row}>
                            <Button style={{ marginLeft: 10, marginRight: 25 }} variant="outlined" startIcon={<ArrowLeftIcon />} onClick={() => history.push('/promotion-dashboard')}>
                                Return
                            </Button>
                            <Typography variant='h3' color='black' style={{ marginTop: 3, marginRight: 20 }}>
                                Scenario Setting
                            </Typography>
                            <Typography variant='h5' style={{ marginTop: 3 }}>
                                <CircleIcon style={{ marginLeft: 15, height: 10, color: 'red' }} />
                                In Progress
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.mainHeader}>
                    <div className={classes.headerItem} style={{ display: 'flex' }}>
                        <Typography variant='h4' color='grey' style={{ display: 'inline', marginRight: 10 }}>
                            Prefill Options from a previous scenario
                        </Typography>
                        <div className={classes.selectBox}>
                            <List style={{ marginTop: 4 }} disabled={props.mode == 'edit' ? true : false} />
                            <OutlinedInput
                                twoline='true'
                                value={scenarios}
                                readOnly
                                placeholder='None Selected'
                                onClick={() => handleClick('scenario')}
                                className={classes.prefillOutlined}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleClick('scenario')}>
                                            {anchorE1 ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                        {anchorE1 &&
                            <div style={{ position: 'relative' }}>
                                <SingleMultiLevelList head='Prefill' id={'prefill'} data={previousScenarios} expand={scenarioExpand} onSubmit={handleScenario}
                                    prevalue={scenariosID} type='scenario_filter' />
                            </div>
                        }

                    </div>
                    <div className={classes.headerItem}>
                        <Typography variant='h4' color='grey' style={{ display: 'inline' }}>
                            SCENARIO NAME
                        </Typography>
                        <Typography variant='h5' color='grey' style={{ display: 'inline' }}>
                            In addition, a generic name dedicated to your workspace will be automatically generated.
                        </Typography>
                    </div>
                    <div className={classes.searchBox}>
                        <div className={classes.searchItem}>
                            <TextField
                                placeholder="Promotion theme name"
                                onChange={handleTheme}
                                variant="outlined"
                                value={promoTheme}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <GTranslateIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ align: 'center', backgroundColor: 'white' }}
                            />
                        </div>
                        <div style={{ marginLeft: 250 }}>
                            <Button style={{ height: 35 }} variant="outlined" onClick={() => history.push('/promotion-dashboard')}>Cancel</Button>
                            <Button style={{ marginLeft: 10, height: 35 }}
                                variant="contained"
                                disabled={productLine && country && promoType && usageCode && modelYear && promoData.promotion_currency &&
                                    (requireCertificate ? promoData.validate_code && reservationStartDate && reservationEndDate && !(!!resStartDateError) && !(!!resEndDateError) : true) && promotionStartDate && promotionEndDate && !(!!promoStartDateError) && !(!!promoEndDateError) ? false : true}
                                onClick={handleFormData}>Apply Sticker</Button>
                        </div>
                    </div>

                </div>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="SCENARIO PROPERTIES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                spacing={1}
                            >
                                <Grid
                                    item
                                    md={7}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}

                                >
                                    <Typography className={classes.fontSetup} style={{ marginBottom: 10 }} variant='h3'>WHAT</Typography>
                                    <div style={{ display: 'flex', marginLeft: -8 }}>
                                        <Typography style={{ marginLeft: 5, marginTop: 12 }}>Live Promotion</Typography>
                                        <span className={classes.mainToggleButton} >
                                            <Switch
                                                //checked={promoCategory}
                                                //onClick={handleCategoryToggle}
                                                checked={promoData && promoData['promotion_category'] == 'Budget' ? true : false}
                                                onClick={(e) => handleSettings('promotion_category', e.target.checked, 'boolean')}
                                                disabled={false} />
                                        </span>
                                        <Typography style={{ marginTop: 12 }}>Budget Forecasting</Typography>
                                    </div>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}

                                    >
                                        <Grid
                                            item
                                            md={3.5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >

                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15, marginRight: 20 }} required>
                                                Promotion Type
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={promoData && promoData['promotion_type'] ? promoData['promotion_type'] : ''

                                                }
                                                // onChange={(value) => handlePromoWhat(value, 'promoType')}
                                                onChange={(e) => handleSettings('promotion_type', e.target.value, 'string')}
                                                //displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ textTransform: 'capitalize' }}
                                            >

                                                {promoConfigData && Object.keys(promoConfigData).length > 0 && Object.keys(promoConfigData)
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: -2 }}
                                        spacing={2}

                                    >
                                        <Grid
                                            item
                                            md={3.7}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15, marginRight: 60 }} required>
                                                Usage Code
                                            </FormLabel>
                                            <MultiSelectDropdown id={'usage_code'} onChange={handleUsageCode} style={{ marginTop: -20 }}
                                                data={usageCode && usageCode.length > 0 ? usageCode : []}
                                                listArray={promoConfigData && promoType && promoConfigData[promoType] &&
                                                    Object.keys(promoConfigData[promoType].length > 0) ?
                                                    Object.keys(promoConfigData[promoType]) : []}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={3.7}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} >
                                                Sales Program Code
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={promoData && promoData['sales_program_code'] ? promoData['sales_program_code'] : ''}
                                                onChange={(e) => handleSettings('sales_program_code', e.target.value, 'string')}
                                                displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                <MenuItem value={' '} key={' '} >
                                                    {' '}
                                                </MenuItem>
                                                {salesCodeList && salesCodeList
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 5 }}
                                        spacing={2}

                                    >
                                        <Grid
                                            item
                                            md={3.7}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <div style={{ marginLeft: -10 }}>
                                                <Checkbox
                                                    checked={requireAmount}
                                                    onChange={(e) => setRequireAmount(e.target.checked)}
                                                    color='primary'
                                                    classes={{
                                                        root: classes.formControlRoot
                                                    }}
                                                    className={classes.checkBoxContainer}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <FormLabel classes={{ root: classes.checkBoxFont }} >
                                                    Require Deposit Amount
                                                </FormLabel>
                                            </div>
                                            {requireAmount &&
                                                <div classes={classes.arrowSelectBox}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15, marginRight: 60 }}>
                                                        Deposit Amount
                                                    </FormLabel>
                                                    <OutlinedInput twoline='true'
                                                        type={'number'}
                                                        // value={depositAmount != 0 ? depositAmount : ''}
                                                        // onChange={handleDepositAmount}
                                                        value={promoData && promoData['deposit_amount'] ? promoData['deposit_amount'] : ''}
                                                        onChange={(e) => handleSettings('deposit_amount', e.target.value, 'integer')}
                                                        className={classes.outlined} />
                                                    <div style={{ display: 'flex', marginTop: 10 }}>
                                                        <ErrorOutlineOutlined />
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Typography variant='h5' color='black' style={{ whiteSpace: 'nowrap' }}>If you need to enter several</Typography>
                                                            <Typography variant='h5' color='black' style={{ whiteSpace: 'nowrap' }}>
                                                                amounts <a href="#" style={{ cursor: 'pointer', textDecoration: 'underline' }}> click here</a>
                                                            </Typography></div>
                                                    </div>
                                                </div>
                                            }
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <div style={{ marginLeft: -10 }}>
                                                <Checkbox
                                                    checked={requireCertificate}
                                                    onChange={(e) => setRequireCertificate(e.target.checked)}
                                                    color='primary'
                                                    classes={{
                                                        root: classes.formControlRoot
                                                    }}
                                                    className={classes.checkBoxContainer}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <FormLabel classes={{ root: classes.checkBoxFont }} >
                                                    Require Certificates, Pincodes or Authorization Code
                                                </FormLabel>
                                            </div>
                                            {requireCertificate &&
                                                <>
                                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15, marginRight: 60 }}>
                                                        Options
                                                    </FormLabel>
                                                    <Select
                                                        disableUnderline
                                                        value={promoData && promoData['validate_code'] ? promoData['validate_code'] : ''}
                                                        onChange={(e) => handleSettings('validate_code', e.target.value, 'string')}
                                                        displayEmpty
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        style={{ textTransform: 'capitalize', width: "60%" }}
                                                    >

                                                        {/* {promoConfigData && promoType && usageCode && promoConfigData[promoType] && promoConfigData[promoType][usageCode] &&
                                                            promoConfigData[promoType][usageCode]['validation'] && promoConfigData[promoType][usageCode]['validation'].length > 0
                                                            && promoConfigData[promoType][usageCode]['validation'] */}
                                                        {validateCodeList && validateCodeList
                                                            .map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Select>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15, marginBottom: 20 }}

                                >

                                    <Typography className={classes.fontSetup} variant='h3'>WHEN</Typography>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        spacing={2}

                                    >

                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} required> Promotion Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: `1px solid ${!!promoStartDateError ? 'red' : '#E0E0E0'}`,
                                                                width: '100%',
                                                                // marginTop: 10
                                                            }
                                                        }}
                                                        value={promotionStartDate}
                                                        onChange={handlePromotionStartDate}
                                                        //  value={promoData && promoData['promotion_start_date'] ? promoData['promotion_start_date'] : null}
                                                        //  onChange={(e) => handleSettings('promotion_start_date', e, 'date')}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={promoStartDateError} error={!!promoStartDateError} />}
                                                        format="MM/DD/YYYY"
                                                        maxDate={promotionEndDate}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel style={{ fontSize: 11, marginLeft: -80 }} >Equal as Registration Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: `1px solid ${!!promoEndDateError ? 'red' : '#E0E0E0'}`,
                                                                width: '100%',
                                                                marginTop: 11
                                                            }
                                                        }}
                                                        value={promotionEndDate}
                                                        onChange={handlePromotionEndDate}
                                                        //value={promoData && promoData['promotion_end_date'] ? promoData['promotion_end_date'] : null}
                                                        //onChange={(e) => handleSettings('promotion_end_date', e, 'date')}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={promoEndDateError} error={!!promoEndDateError} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={promotionStartDate}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: -1 }}
                                        spacing={2}

                                    >
                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} required={requireCertificate}> Reservation Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: `1px solid ${!!resStartDateError ? 'red' : '#E0E0E0'}`,
                                                                width: '100%',
                                                                // marginTop: 10
                                                            }
                                                        }}
                                                        value={reservationStartDate}
                                                        onChange={handleReservationStartDate}
                                                        //value={promoData && promoData['reservation_start_date'] ? promoData['reservation_start_date'] : null}
                                                        // onChange={(e) => handleSettings('reservation_start_date', e, 'date')}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={resStartDateError} error={!!resStartDateError} />}
                                                        format="MM/DD/YYYY"
                                                        maxDate={reservationEndDate}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: `1px solid ${!!resEndDateError ? 'red' : '#E0E0E0'}`,
                                                                width: '100%',
                                                                marginTop: 41
                                                            }
                                                        }}
                                                        value={reservationEndDate}
                                                        onChange={handleReservationEndDate}
                                                        //value={promoData && promoData['reservation_end_date'] ? promoData['reservation_end_date'] : null}
                                                        //onChange={(e) => handleSettings('reservation_end_date', e, 'date')}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={resEndDateError} error={!!resEndDateError} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={reservationStartDate}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: -1 }}
                                        spacing={1}

                                    >
                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} >Invoice Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: '97%',
                                                                marginTop: 10,
                                                                marginLeft: -8
                                                            }
                                                        }}
                                                        value={invoiceStartDate}
                                                        onChange={handleInvoiceStartDate}
                                                        //value={promoData && promoData['invoice_start_date'] ? promoData['invoice_start_date'] : null}
                                                        // onChange={(e) => handleSettings('invoice_start_date', e, 'date')}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={7}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15, paddingBottom: 20, borderTop: '1px dashed #E0E0E0', marginBottom: 5 }}

                                >
                                    <Typography className={classes.fontSetup} style={{ marginTop: 15, marginBottom: 15 }} variant='h3'>WHERE</Typography>

                                    <Grid
                                        item
                                        md={10.3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        spacing={2}
                                    // style={{ marginTop: 15 }}

                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 19 }} required>
                                            Promotion Location
                                        </FormLabel>
                                        <OutlinedInput
                                            twoline='true'
                                            value={promoLocation}
                                            readOnly
                                            onClick={() => handleClick('location')}
                                            className={classes.outlined}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => handleClick('location')}>
                                                        {anchorloc ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {anchorloc &&
                                            <NestedDropdown2 head='Promotion Location' id={'locations'} data={props.locations} expand={expanded} onSubmit={handleCheckedLoc}
                                                prevalue={selectedLoc} selected={selectedLocItems} type='loc_filter' />
                                        }
                                    </Grid>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                        spacing={2}

                                    >
                                        <Grid
                                            item
                                            md={3.5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: -2, marginRight: 60 }} required>
                                                Product Line
                                            </FormLabel>
                                            <MultiSelectDropdown onChange={handleProductLine} data={productLine && productLine.length > 0 ? productLine : []} listArray={promoDivisionArray} />
                                        </Grid>
                                        <Grid
                                            item
                                            md={3.5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: -2, marginRight: 60 }} required>
                                                Model Year
                                            </FormLabel>
                                            <MultiSelectDropdown onChange={handleModelYear} data={modelYear && modelYear.length > 0 ? modelYear : []} listArray={promoModelYearArray} />
                                        </Grid>

                                        <Grid
                                            item
                                            md={3.5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        //style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 18 }} required>
                                                Promotion Currency
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                //value={promoCurrency}
                                                //onChange={(value) => handlePromoWhere(value, 'currency')}
                                                value={promoData && promoData['promotion_currency'] ? promoData['promotion_currency'] : ''}
                                                onChange={(e) => handleSettings('promotion_currency', e.target.value, 'string')}
                                                displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ textTransform: 'capitalize' }}
                                            >

                                                {currency && currency.length > 0 && currency
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15, paddingBottom: 20, borderTop: '1px dashed #E0E0E0' }}

                                >
                                    <Typography className={classes.fontSetup} style={{ marginTop: 15 }} variant='h3'>HOW</Typography>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    //style={{ marginTop: 10 }}

                                    >
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} >Stackable Promotion</FormLabel>
                                            <div style={{ display: 'flex' }}>
                                                <Typography style={{ marginLeft: 5 }}>No</Typography>
                                                <span className={classes.toggleStackableButton} >
                                                    <Switch
                                                        //checked={stackablePromotion}
                                                        //onClick={handleStackableToggle}
                                                        checked={promoData && promoData['stackable_promotion']}
                                                        onClick={(e) => handleSettings('stackable_promotion', e.target.checked, 'boolean')}
                                                        disabled={false} />
                                                </span>
                                                <Typography>Yes</Typography>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }}>Promotion By</FormLabel>
                                            <div style={{ display: 'flex' }}>
                                                <Typography style={{ marginLeft: 5 }}>Dealers</Typography>
                                                <span className={classes.toggleButton} >
                                                    <Switch
                                                        //checked={promotionBy}
                                                        // onClick={handlePromotionByToggle}
                                                        checked={promoData && promoData['promotion_by']}
                                                        onClick={(e) => handleSettings('promotion_by', e.target.checked, 'boolean')}
                                                        disabled={false} />
                                                </span>
                                                <Typography>Distributor</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 30 }}

                                    >
                                        {/* {stackablePromotion &&
                                        <div className={classes.arrowSelectBox}>
                                            <List />
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }}>
                                                Promotion Scenario
                                            </FormLabel>
                                            <PromoMultiSelect id='promotions-scenario' onChange={(value) => handleFilters(value, 'promotions_scenario')}
                                                options={[]} />
                                        </div>
                                        } */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
        </LoadingOverlay>
    );

};

const mapStateToProps = state => {
    return {
        filterData: state.promotionData.promoFilters,
        configData: state.calculationAnalysisData.budgetData,
        locationData: state.promotionData.promoLocationFilters,
        scenarioList: state.promotionData.prefillScenarioList,
        loading: state.promotionData.loading,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        getConfigData: () => dispatch(getBudgetingConfig('promotionType')),
        getScenarioList: (data) => dispatch(getPrefillScenarioList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioSetting);