import React, { useEffect } from 'react';
import {
    Typography, Switch, Stack, FormControlLabel, FormGroup, Checkbox,
    InputAdornment, IconButton, TextField, FormControl, ToggleButton, ToggleButtonGroup,
    Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    Accordion,
    AccordionSummary,
    AccordionDetails, Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import GroupsIcon from '@mui/icons-material/Groups';
import clsx from 'clsx';
import { ExpandMore, ExpandLess, AddCircleOutline } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createGlobalSetup, getPromoTypes, getBudgetingConfig, getMarketFilters, getModels, getCustomGroups } from '../../../../../../redux/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NestedDropdown, NestedDropdown2, PromoMultiSelect } from '../../../../Components'
import CustomGroups from './CustomGroups';
import '../../../../Styles/scenario.css'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        paddingBottom: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: 10,
        padding: 15,
    },
    rightContainer: {
        // backgroundColor: '#F8F8F8'
    },
    leftContainer: {
        marginTop: '12px',
        padding: '0px 45px',
        backgroundColor: '#F8F8F8'

    },
    defineContainer: {
        marginTop: '12px',
        // padding: '0px 45px',
        backgroundColor: '#F8F8F8',
        maxHeight: 634,
        height: 634,
        overflowY: 'auto',
        border: "0.8px solid #E2E2E2",
        borderRadius: 4,

    },
    chipContainer: {
        // paddingLeft: 30,
        display: 'flex',
        flexDirection: 'row',
        // paddingBottom: 10,
        backgroundColor: '#F8F8F8',
        padding: '20px 0px 20px 30px !important'
    },
    subContainer: {
        padding: '10px 16px 16px 16px',
        alignItems: 'center',
        //marginLeft: 30,
    },
    iconContainer: {
        marginTop: 40
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },

    searchBox: {
        backgroundColor: theme.palette.white
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-around',
        border: '1px solid #E2E2E2',
        height: '48px',
        borderRadius: '8px',
        // marginTop: 10,
        padding: '8px',
        width: 'fit-content',
        marginBottom: 42
    },

    filterItem: {
        display: 'flex',
        marginRight: 12
    },
    filterContainer: {
        marginRight: 12,
        //width: '25%',
        //marginTop: -5,
        display: 'flex',
        flexDirection: 'column'
    },
    rightHeader: {
        width: '100%'
        // display: 'flex',
        // justifyContent: 'space-between'
    },
    chip: {
        backgroundColor: 'white',
        border: '1px solid #E2E2E2'
    }
}));
const DefineMarket = (props) => {

    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [productLine, setProductLine] = React.useState([]);
    const [country, setCountry] = React.useState('USA');
    const [enableDesc, setEnableDesc] = React.useState(false);
    const [externalDesc, setExternalDesc] = React.useState('');
    const [stickerDesc, setStickerDesc] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [status, setStatus] = React.useState('pending');
    const [definedModels, setDefinedModels] = React.useState([]);
    const [models, setModels] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [customEn, setCustomEn] = React.useState(false);
    const [expanded, setExpanded] = React.useState([]);
    const [anchorloc, setAnchorloc] = React.useState(false);
    const [marketExpanded, setMarketExpanded] = React.useState([]);
    const [modelYear, setModelYear] = React.useState('');
    const [checkedModel, setCheckedModel] = React.useState([]);
    const [displaySelected, setDisplaySelected] = React.useState(true);
    const [toggle, setToggle] = React.useState(true);
    const [selectedModels, setSelectedModels] = React.useState([]);
    const [selectedModelItems, setSelectedModelItems] = React.useState([]);
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [checkedLoc, setCheckedLoc] = React.useState([]);
    const [region, setRegion] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [itemOn, setItemOn] = React.useState('');
    const [regionList, setRegionList] = React.useState([]);
    const [stickerModels, setStickerModels] = React.useState([]);
    const [defaultExpanded, setDefaultExpanded] = React.useState([]);
    const [loc, setLoc] = React.useState({});
    useEffect(() => {
        setStickerDesc(props.stickerName)
    }, [props.stickerName]);

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData)
        }
    }, [props.configData]);
    useEffect(() => {
        if (props.modelData && props.modelData.length > 0) {
            setModels(props.modelData)
            setDefinedModels(props.modelData)
        }
    }, [props.modelData]);
    useEffect(() => {
        if (filterData && filterData.length > 0 && definedModels) {
            setModels(definedModels.filter((item, i) => filterData.includes(item.category)))
            if (selectedModelItems && Object.keys(selectedModelItems).length > 0) {//to remove models if we change modelyears
                var data = selectedModelItems && Object.entries(selectedModelItems).filter(([k, v]) => filterData.includes(v.material_group1)).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                setSelectedModelItems(data)
                var temp = stickerModels && Object.entries(stickerModels).filter(([k, v]) => filterData.some(prefix => k.startsWith(prefix))).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                console.log(temp)
                setStickerModels(temp)
                props.handleDefineMarket(data, temp, 'model')
            }
        }
        else {
            setModels(definedModels)
            setSelectedModelItems({})
            setStickerModels({})
            props.handleDefineMarket({}, {}, 'model')
        }
    }, [filterData]);
    useEffect(() => {
        if (props.locationData && props.locationData.length > 0) {
            var region = props.locationData.map((item, index) => item.category)
            setRegionList(region)
            setLocations(props.locationData)
        }
    }, [props.locationData]);
    useEffect(() => {
        if (props.defineMarket) {
            setSelectedLocItems(props.defineMarket['location'])
            setSelectedModelItems(props.defineMarket['model'])
        }
        else {
            setSelectedLocItems([])
            setSelectedModelItems([])
        }
    }, [props.defineMarket])
    useEffect(() => {
        if (props.defineMarketChild) {
            setSelectedLoc(props.defineMarketChild['location'])
            setStickerModels(props.defineMarketChild['model'])
            setDefaultExpanded(props.defineMarketChild['model'] ? Object.keys(props.defineMarketChild['model']) : [])
            setFilterData(props.defineMarketChild['model_year'])
            setModelYear(props.defineMarketChild['model_year'] ? props.defineMarketChild['model_year'][0] : '')
        }
        else {
            setSelectedLoc([])
            setStickerModels([])
            setFilterData([])
            setModelYear([])
        }
    }, [props.defineMarketChild])
    useEffect(() => {
        if (props.customDetails && props.customDetails.records) {
            var model = selectedModelItems
            var modelDisplay = stickerModels
            var mYears = filterData ? filterData : []
            var loc = selectedLocItems
            var locDisplay = selectedLoc
            var mFlag = 0;
            var lFlag = 0;
            props.customDetails.records.map((item, index) => {
                if (item.models) {
                    mFlag++
                    if (item.exclude) {
                        item.models && Object.keys(item.models).map(itemx =>
                            delete model[itemx]
                        )
                        item.model_display && Object.keys(item.model_display).map(itemx =>
                            delete modelDisplay[itemx]
                        )
                    }
                    else {
                        model = { ...item.models, ...model }
                        modelDisplay = { ...item.model_display, ...modelDisplay }
                        mYears = [...new Set([...mYears, ...item.model_year])];
                        setSelectedModelItems(prev => ({ ...item.models, ...prev }))
                        setStickerModels(prev => ({ ...item.model_display, ...prev }))
                    }

                }
                else {
                    if (item.location) {
                        lFlag++;
                        if (item.exclude) {
                            item.location && Object.keys(item.location).map(itemx =>
                                delete loc[itemx]
                            )
                            item.location_display && Object.keys(item.location_display).map(itemx =>
                                delete locDisplay[itemx]
                            )
                        }
                        else {
                            loc = { ...item.location, ...loc }
                            locDisplay = { ...item.location_display, ...locDisplay }
                            setSelectedLocItems(prev => ({ ...item.location, ...prev }))
                            setSelectedLoc(prev => ({ ...item.location_display, ...prev }))
                        }

                    }
                }
            })
            if (mFlag > 0) {
                setFilterData(mYears)
                props.handleDefineMarket(model, modelDisplay, 'model', mYears)

            }
            if (lFlag > 0)
                props.handleDefineMarket(loc, locDisplay, 'location')
        }
    }, [props.customDetails])
    function handleModelYear(event) {
        setModelYear(event.target.value)
    }
    function handleRegion(event) {
        setRegion(event.target.value)
    }



    function handleCheckedData(selected, child, type) {
        setAnchorEl(false)
        if (type !== 'cancel') {
            setSelectedModelItems(selected)
            setStickerModels(child)
            props.handleDefineMarket(selected, child, 'model')
            setAnchorEl(false)
            setDefaultExpanded(child ? Object.keys(child) : [])


        }
    };
    function handleCheckedLoc(selected, child, type) {
        setAnchorloc(false)
        if (type !== 'cancel') {
            setSelectedLoc(child)
            setSelectedLocItems(selected)

            props.handleDefineMarket(selected, child, 'location')
            setAnchorEl(false)
        }
    };

    function handleToggle(event) {
        setToggle(event.target.checked);

    };
    function handleDisplay(event) {
        setDisplaySelected(event.target.checked ? false : true);
    };
    function handleClose() {
        setOpen(false)
        setItemOn('')
    }
    function handleCustomData(data) {

        props.getCustomGroupById(data)
        setCustomEn(false)
    }
    function handleSubmit() {
        var loc = [];
        var locItems = [];
        var model = [];
        var modelItems = [];
        setOpen(false)
        var key = [...Object.values(itemOn.relationship), ...itemOn.fields?.product_line ? itemOn.fields.product_line : []].join("_").toString()
        if (displaySelected) {
            if (toggle) {

                delete stickerModels[key]
                delete selectedModelItems[key]
                props.handleDefineMarket(selectedModelItems, stickerModels, 'model')
            }
            else {
                delete selectedLoc[key]
                delete selectedLocItems[key]
                props.handleDefineMarket(selectedLocItems, selectedLoc, 'location')
            }
        }
        else {
            if (toggle) {
                var model = { ...selectedModelItems, [key]: { ...itemOn.relationship, 'product_line': itemOn.fields?.product_line } }
                var child = { ...stickerModels, [key]: {} }
                setSelectedModelItems(model)
                setStickerModels(child)
                props.handleDefineMarket(model, child, 'model')
            }
            else {
                var loc = { ...selectedLocItems, [key]: itemOn.relationship }
                setSelectedLocItems(loc)
                var child = { ...selectedLoc, [key]: {} }
                setSelectedLoc(child)
                props.handleDefineMarket(loc, child, 'location')
            }
        }
        setItemOn('')
    }
    function handleChipItem(item) {
        setOpen(true)
        setItemOn(item)
    }
    const handleFilters = (value, field) => {
        setFilterData(value)
        if (value.length > 0)
            setModelYear(value[0])
        props.onChange(value, 'material_group1')
    }
    const handleClick = (type) => {
        if (type == 'model') {
            setAnchorEl(!anchorEl)
            const isExpanded = !anchorEl
            setExpanded(isExpanded ? [...expanded, 'model_filter'] : []);
        }
        else if (type == 'custom') {
            setCustomEn(!customEn)
            const isExpanded = !customEn
            setExpanded(isExpanded ? [...expanded, 'custom'] : []);
        }
        else {
            setAnchorloc(!anchorloc)
            const isExpanded = !anchorloc
            setExpanded(isExpanded ? [...expanded, 'loc_filter'] : []);
        }
    };
    const handleAccordionChange = (panel, type, relationship) => (event, isExpanded) => {
        if (isExpanded) {
            if (displaySelected) {
                setDefaultExpanded(defaultExpanded.filter(item => item != relationship))
            }
            else {
                setDefaultExpanded([...defaultExpanded, relationship])
            }
        }
        else {
            if (displaySelected) {
                setDefaultExpanded([...defaultExpanded, relationship])

            }
            else {
                setDefaultExpanded(defaultExpanded.filter(item => item != relationship))
            }
        }
        setMarketExpanded(isExpanded ? [...marketExpanded, panel + "-" + type] : marketExpanded.filter(item => item != panel + "-" + type));
    };

    const renderNestedItems = (items) => {

        return items && items.filter((item, index) =>
            toggle ?
                displaySelected ? stickerModels && Object.keys(stickerModels).length > 0 ? Object.keys(stickerModels).some(filterItem => filterItem.startsWith(`${Object.values(item.relationship).join("_")}_`)) : '' :
                    !(stickerModels && Object.keys(stickerModels).includes([...Object.values(item.relationship), ...item.fields?.product_line ? item.fields.product_line : []].join("_").toString())
                        && Object.keys(stickerModels).some(filterItem => filterItem.startsWith(`${Object.values(item.relationship).join("_")}_`)))
                :
                displaySelected ? selectedLoc && Object.keys(selectedLoc).length > 0 ? Object.keys(selectedLoc).some(filterItem => filterItem.startsWith(`${Object.values(item.relationship).join("_")}`)) : '' :
                    !(Object.keys(selectedLoc).includes(`${Object.values(item.relationship).join("_")}`))
        )
            .map((item, index) => {
                var key = [...Object.values(item.relationship), ...item.fields?.product_line ? item.fields.product_line : []].join("_").toString()
                return (
                    <>

                        {item.type == 'material_group1' || item.type == 'region' ?
                            renderNestedItems(item.children)
                            :
                            item.children && item.children.length > 0 ? (
                                <div key={index} style={{ flexGrow: 1, width: '100%' }} >
                                    <Accordion
                                        expanded={displaySelected ? !(defaultExpanded.includes(key)) : defaultExpanded.includes(key)}//marketExpanded.includes(item.category + "-" + item.type)
                                        onChange={handleAccordionChange(item.category, item.type, key)}
                                        style={{ margin: 0, borderBottom: "0.8px solid #E2E2E2", zindex: 1 }}
                                        fullWidth
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMore color={marketExpanded.includes(item.category + "-" + item.type) ? 'primary' : 'black'} />}
                                            className={classes.accordionMenu}
                                            style={{ paddingLeft: item.type == 'material_group3' ? 20 : 10 }}
                                        >
                                            <Typography variant='h4'
                                                color={'grey'}
                                            >
                                                {item.category}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ padding: 0,/* display: item.type == 'material_group3' && 'flex':'block', flexDirection: item.type == 'material_group3' && 'row'*/ }}
                                            className={(item.type == 'material_group3') && classes.chipContainer}
                                        >
                                            {renderNestedItems(item.children)}
                                        </AccordionDetails>
                                    </Accordion>
                                </div >
                            ) :
                                displaySelected ?
                                    (<div style={{ paddingLeft: 30, marginRight: 5 }}>
                                        <Chip
                                            label={item.category}
                                            variant="outlined"
                                            className={classes.chip}
                                            sx={{ backgroundColor: 'white' }}
                                            //onClick={handleClick}
                                            onDelete={() => handleChipItem(item)}

                                        />
                                    </div>
                                    )
                                    :
                                    (toggle ? (Object.keys(selectedModelItems).indexOf(key) < 0) :
                                        (Object.keys(selectedLocItems).indexOf(key) < 0)) && (
                                        <div style={{ paddingLeft: 30, marginRight: 5 }}>
                                            <Chip
                                                label={item.category}
                                                variant="outlined"
                                                onDelete={() => handleChipItem(item)}
                                                deleteIcon={<AddCircleOutline />}
                                                sx={{ backgroundColor: 'white' }}
                                            //onClick={handleClick}

                                            />
                                        </div>
                                    )
                        }
                    </>
                )
            });
    };
    return (
        <div className={classes.root}>
            <Grid container item spacing={1}>
                <Grid
                    item xs={12} md={12}
                    classes={{ root: classes.leftContainer }}
                >
                    <div>
                        <Typography variant='h3' style={{ marginBottom: '10px' }}>SELECT YOUR MARKET</Typography>
                        <div style={{ display: 'flex', gap: 4 }}>
                            <div className={`filter-menu-container ${classes.filterBox}`}>
                                <Typography className='promo-font' color='#19170F'>Filter</Typography>
                                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="28" viewBox="0 0 2 28" fill="none">
                                    <path d="M1 1L0.999999 27" stroke="#E2E2E2" stroke-width="0.8" stroke-linecap="round" />
                                </svg>
                                <div className={classes.filterItem}>
                                    <Typography variant='h4' color='grey'>Model Year</Typography>
                                    <PromoMultiSelect id='model-year' onChange={(value) => handleFilters(value, 'material_group1', 'material_master')}
                                        options={props.modelYears ? props.modelYears : []} prevalue={filterData ? filterData : []} />
                                </div>
                                <div className={classes.filterContainer}>
                                    <div className={classes.filterItem}>
                                        <Typography variant='h4' color='grey'>Model Selection</Typography>
                                        <IconButton onClick={() => handleClick('model')}>{anchorEl ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                    </div>
                                    {anchorEl &&
                                        <NestedDropdown2 head='Model Selection' id={'model'} data={models} expand={expanded} onSubmit={handleCheckedData}
                                            prevalue={stickerModels} selected={selectedModelItems} type='model_filter' />}
                                </div>
                                <div className={classes.filterContainer}>
                                    <div className={classes.filterItem}>
                                        <Typography variant='h4' color='grey'>Location Selection</Typography>
                                        <IconButton onClick={() => handleClick('location')}>{anchorloc ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                    </div>{console.log(locations)}
                                    {console.log(props.country)}
                                    {console.log(locations ? locations.filter(item => item.relationship?.country == props.country ? item : '') : [])}
                                    {anchorloc &&
                                        <NestedDropdown2 head='Location Selection' id={'locations'}
                                            data={locations ? locations.filter(item => item.relationship?.country == props.country ? item : '') : []}
                                            expand={expanded} onSubmit={handleCheckedLoc}
                                            prevalue={selectedLoc} selected={selectedLocItems} type='loc_filter'
                                        />
                                    }
                                </div>

                            </div>
                            <div className={classes.filterContainer}>
                                <div className={`custom-container`}>
                                    <GroupsIcon />
                                    <Typography variant='h4' color='grey'>Custom Group</Typography>
                                    <IconButton onClick={() => handleClick('custom')}>{customEn ? <ExpandLess /> : <ExpandMore />}</IconButton>

                                </div>
                                {customEn &&
                                    <div>
                                        <CustomGroups customGroup={props.customGroup} productLine={props.productLine} onChange={handleCustomData} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Grid >
                <Grid
                    item xs={12} md={12}
                    className={classes.rightContainer}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                    </div>
                    <div className={classes.rightHeader}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <FormControlLabel
                                    value={displaySelected}
                                    control={<Checkbox checked={displaySelected ? false : true} />}
                                    label={<Typography variant='h4' color='grey'>{toggle ? 'Display Unselected Models' : 'Display Unselected States'}</Typography>}
                                    labelPlacement="end"
                                    onChange={handleDisplay}
                                />
                            </div>
                            <div >
                                <Stack direction="row" spacing={1} alignItems="center" style={{ height: 30 }}>
                                    <Typography variant='h4' color='black'>STATES</Typography>
                                    <Switch sx={{ m: 1 }} checked={toggle} className={classes.toggleButton}
                                        onChange={handleToggle} />
                                    <Typography variant='h4' color='black'>MODELS</Typography>
                                </Stack>
                            </div>
                        </div>
                        <div>
                            {toggle ?
                                <ToggleButtonGroup
                                    color="primary"
                                    value={modelYear}
                                    exclusive
                                    onChange={handleModelYear}
                                    aria-label="Platform"
                                    style={{ position: 'relative', zindex: -1 }}
                                >
                                    {props.country != 'CA' ? filterData && filterData.sort().map(item =>
                                        <ToggleButton value={item}>{item}</ToggleButton>

                                    )
                                        : (
                                            <ToggleButton value={'Canada'}>Canada</ToggleButton>
                                        )}
                                </ToggleButtonGroup>
                                :
                                <ToggleButtonGroup
                                    color="primary"
                                    value={region}
                                    exclusive
                                    onChange={handleRegion}
                                    aria-label="Platform"
                                    style={{ position: 'relative', zindex: -1 }}
                                >
                                    {regionList && regionList.map(item =>
                                        <ToggleButton value={item}>{item}</ToggleButton>
                                    )}
                                </ToggleButtonGroup>
                            }
                        </div>
                        <div className={classes.defineContainer} style={{ position: 'relative', zindex: -1, backgroundColor: '#F8F8F8', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', paddingTop: !toggle ? 10 : 0 }} className={!toggle && classes.chipContainer}>
                                {/* {displaySelected ?
                                    toggle ?
                                        renderNestedItems(checkedModel.filter((item, index) => item.category == modelYear))
                                        :
                                        renderNestedItems(checkedLoc.filter((item, index) => item.category == region))
                                    : }*/
                                    toggle ?
                                        stickerModels && Object.keys(stickerModels).length > 0 &&
                                        renderNestedItems(models.filter((item, index) => item.category == modelYear))
                                        :
                                        selectedLoc && Object.keys(selectedLoc).length > 0 &&
                                        renderNestedItems(locations && locations.filter((item, index) => item.category == region))
                                }
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle color='grey' style={{ cursor: 'move' }} id="dialog-title">
                    {displaySelected ? 'REMOVE FROM SELECTION ?' : 'ADD TO SELECTION ?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Chip
                            label={itemOn.category}
                            variant="outlined"
                            className={classes.chip}
                            sx={{ backgroundColor: 'white' }}

                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={handleSubmit}>yes</Button>
                </DialogActions>
            </Dialog>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getPromotionTypes: () => dispatch(getPromoTypes()),
        getMarketFilters: (table, data) => dispatch(getMarketFilters(table, data)),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter')),
        getCustomGroupById: (id) => dispatch(getCustomGroups(id))
    }
}

const mapStateToProps = state => {
    return {
        promotionTypes: state.promotionData.promoTypes,
        loading: state.promotionData.loading,
        customDetails: state.promotionData.customGroupList
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DefineMarket);