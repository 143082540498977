import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import {
    getApprovalGroupData, deleteApprovalDesigner, getAllApprovalDesigner
} from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { Toolbar } from './';
import { useHistory } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RingLoader from 'react-spinners/RingLoader';
import { Visibility } from '@mui/icons-material';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    editIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: '#FE642E',
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
    },
    container: {
        padding: 10
    }
    ,
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const ApprovalDesignerMain = props => {
    useEffect(() => {
        props.getAllApprovalDesigner(1, 100);
        props.getApprovalGroupData();
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [itemToDelete, setItemToDelete] = React.useState(null);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAllApprovalDesigner(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(1);
        setRowsPerPage(event.target.value);
        // props.getAllApprovalDesigner(1, event.target.value);
        props.getAllApprovalDesigner(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.customerMasterXRefData) {
            setContractsTableComplete(props.customerMasterXRefData);
        }
    }, [props.customerMasterXRefData]);

    function editCustomer(id) {
        history.push({
            pathname: '/approval-limits/edit-approval/' + id,
            id: id
        });
    }
    function viewCustomer(id) {
        history.push({
            pathname: '/approval-limits/view-approval/' + id,
            id: id
        });
    }

    function handleItemClick(id) {
        localStorage.setItem('currentAddContractID', id);
        props.getcontractViewdetails(id);
    }
    const [approvalData, setApprovalData] = React.useState([]);
    useEffect(() => {
        if (props.approvalData) {
            setApprovalData(props.approvalData)
        }
    }, [props.approvalData])

    const [approvalGroupData, setApprovalGroupData] = React.useState([]);
    useEffect(() => {
        if (props.approvalGroupData) {
            setApprovalGroupData(props.approvalGroupData)
        }
    }, [props.approvalGroupData])

    const handleDelete = () => {
        props.deleteApprovalDesigner(itemToDelete);
        setConfirmDeleteOpen(false);
    };

    const openConfirmDeleteDialog = (id) => {
        setItemToDelete(id);
        setConfirmDeleteOpen(true);
    };

    const closeConfirmDeleteDialog = () => {
        setConfirmDeleteOpen(false);
        setItemToDelete(null);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<RingLoader size={100} />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Approval Levels</Typography>
                    <Toolbar />
                </div>
                {approvalData && approvalData.length > 0 ?
                    <TableContainer style={{ marginTop: -25 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Functionality</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Primary Anchor</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Primary Anchor Value</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Secondary Anchor</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Secondary Anchor Value</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Step Number</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Step Description</TableCell>
                                    <TableCell align='center' className={classes.tabHead}> Threshold Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Positive Threshold Value</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Negative Threshold Value</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Threshold Unit</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Approval Group</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Notification Group</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>External Approval</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ width: 200 }} >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {approvalData

                                    .sort((a, b) => a.application_type < b.application_type ? 1 : -1)
                                    .sort((a, b) => (a.application_type >= b.application_type) && (a.functionality > b.functionality) ? 1 : -1)
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.contract_number}>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.application_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.functionality.replace(/_/g, ' ')}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.primary_anchor.replace(/_/g, ' ')}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.primary_anchor_value}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.secondary_anchor.replace(/_/g, ' ')}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.secondary_anchor_value}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.step_number}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.step_description}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.incentive_threshold_type.replace(/_/g, ' ')}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.incentive_amount != 0 ? item.incentive_amount : ''}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.negative_threshold_value != 0 ? item.negative_threshold_value : ''}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.currency}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>
                                                    {approvalGroupData && approvalGroupData.records && approvalGroupData.records.length > 0 &&
                                                        approvalGroupData.records.map(item2 => {
                                                            if (item2.ID === item.approval_group) {
                                                                return item2['name']
                                                            }
                                                        })
                                                    }

                                                </TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.notification_group}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.external_approval}</TableCell>
                                                <TableCell align='center'>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewCustomer(item.ID)}
                                                            size="large">
                                                            <Visibility color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editCustomer(item.ID)}
                                                            size="large">
                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                        <IconButton
                                                            // onClick={() => props.deleteApprovalDesigner(item.ID)}
                                                            onClick={() => openConfirmDeleteDialog(item.ID)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                        component="div"
                        count={props.approvalData && props.approvalData.length ? props.approvalData.length : 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={handleChangePage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        classes={{
                            caption: classes.caption,
                            root: classes.paginationRoot
                        }}
                    /> */}
                    </TableContainer>

                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog open={confirmDeleteOpen} onClose={closeConfirmDeleteDialog}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    Are you sure you want to delete this item?
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmDeleteDialog} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} variant='contained' color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        approvalData: state.customerData.approvalDesignerGetAll,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllApprovalDesigner: (pagination, limit) => dispatch(getAllApprovalDesigner(pagination, limit)),
        deleteApprovalDesigner: (id) => dispatch(deleteApprovalDesigner(id)),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalDesignerMain);