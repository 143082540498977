import React, { useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import { Button, Card, Typography, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, FormLabel, Select, MenuItem, OutlinedInput, ListItemText, Checkbox } from '@mui/material';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import { ChipInput } from "../../components/Inputs";
import { createConfigurationDesigner, getLblDispDesFieldValue, getConfigurationDesigner } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '20px 16px',
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 41,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
        fontSize: theme.typography.h4.fontSize
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    gridContainer: {
        width: '100%'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 5,
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));
const ConfigurationDesign = props => {
    const classes = useStyles();
    const { className } = props;
    const [selectedValue, setSelectedValue] = React.useState('');
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [currentID, setCurrentID] = React.useState(0);
    const [currentReportID, setCurrentReportID] = React.useState(0);
    const [currentSuppressID, setCurrentSuppressID] = React.useState(0);
    const [currentCalculationID, setCurrentCalculationID] = React.useState(0);
    const [currentLinesID, setCurrentLinesID] = React.useState(0);
    const [postingParallelRunID, setpostingParallelRunID] = React.useState(0);
    const [workflowName, setWorkflowName] = React.useState([]);
    const [workflowNameArray, setWorkflowNameArray] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [workflowData, setWorkFlowData] = React.useState([]);
    const [reportingCategory, setReportingCategory] = React.useState([]);
    const [suppressOnlineRun, setSuppressOnlineRun] = React.useState('');
    const [suppressOnlineRunData, setSuppressOnlineRunData] = React.useState([]);
    const [calculationPerformanceImprovement, setCalculationPerformanceImprovement] = React.useState('');
    const [calculationPerformanceImprovementData, setCalculationPerformanceImprovementData] = React.useState([]);
    const [reportingCategoryData, setReportingCategoryData] = React.useState([]);
    const [maximumCalculationLines, setMaximumCalculationLines] = React.useState('');
    const [maximumCalculationData, setMaximumCalculationData] = React.useState([]);
    const [postingParallelRun, setPostingParallelRun] = React.useState('');
    const [postingParallelRunData, setPostingParallelRunData] = React.useState([]);

    const isAllSelected = workflowNameArray && Object.entries(workflowNameArray).length > 0 && selected.length === Object.entries(workflowNameArray).length;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        var data = {
            "data_source": "document_header",
            "application_type": "Customer Rebate",
            "assignment_workflow": true
        }
        props.fieldDesigner(data, 'post');
        props.getClaimData();
    }, [])

    useEffect(() => {
        if (props.claimData && props.claimData.length > 0) {
            props.claimData.forEach(data => {
                if (data.key_name === "reporting_category") {
                    setCurrentReportID(data.id);
                    if (data.config_fields != null) {
                        setReportingCategory(data.config_fields)
                        setReportingCategoryData(data.config_fields)
                    }
                    else {
                        setReportingCategory([])
                        setReportingCategoryData([])
                    }
                }
                else if (data.key_name === "document_assignment_config") {
                    setCurrentID(data.id);
                    if (data.config_fields != null) {
                        setWorkflowName(data.config_fields)
                        setWorkFlowData(data.config_fields)
                    }
                    else {
                        setWorkflowName([])
                        setWorkFlowData([])
                    }
                }
                else if (data.key_name === "suppress_online_run") {
                    setCurrentSuppressID(data.id);
                    if (data.config_fields != null) {
                        setSuppressOnlineRun(data.config_fields)
                        setSuppressOnlineRunData(data.config_fields)
                    }
                    else {
                        setSuppressOnlineRun('')
                        setSuppressOnlineRunData([])
                    }
                }
                else if (data.key_name === "maximum_calculation_lines") {
                    setCurrentLinesID(data.id);
                    if (data.config_fields != null) {
                        setMaximumCalculationLines(data.config_fields)
                        setMaximumCalculationData(data.config_fields)
                    }
                    else {
                        setMaximumCalculationLines('')
                        setMaximumCalculationData([])
                    }
                }
                else if (data.key_name === "posting_parallel_run") {
                    setpostingParallelRunID(data.id);
                    if (data.config_fields != null) {
                        setPostingParallelRun(data.config_fields)
                        setPostingParallelRunData(data.config_fields)
                    }
                    else {
                        setPostingParallelRun('')
                        setPostingParallelRunData([])
                    }
                }
                else if (data.key_name === "calculation_performance_improvement") {
                    setCurrentCalculationID(data.id);
                    if (data.config_fields != null) {
                        setCalculationPerformanceImprovement(data.config_fields)
                        setCalculationPerformanceImprovementData(data.config_fields)
                    }
                    else {
                        setCalculationPerformanceImprovement('')
                        setCalculationPerformanceImprovementData([])
                    }
                }
            });
        }
    }, [props.claimData]);

    useEffect(() => {
        var newArray = {}
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display && v.key !== 'application_type' && v.key !== 'document_analyst')
                .map(([k, v]) => {
                    newArray[v.key] = v.current
                })
            setWorkflowNameArray(newArray)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])
    function handleOpen() {
        setSelected(workflowName)
        setOpen(true)
    }
    function onCancel() {
        setOpen(false)
        setSelected([])
    }
    const handleSelectValues = (event, value, type) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected(selected.length === Object.entries(workflowNameArray).length ? [] : Object.entries(workflowNameArray).map(([key, value]) => key));
        }
        else
            setSelected(newSelected)
    }
    function onAdd() {
        setWorkflowName(selected)
        setOpen(false)
        setSelected([])
    }
    function handleClose() {
        setOpen(false)
    }
    const handleChange = (fieldName) => {
        setSelectedRowName(fieldName);
        if (fieldName == 'workflow_assignment') {
            setSelectedValue(workflowName)
        }
        if (fieldName == 'reporting_category') {
            setSelectedValue(reportingCategory)
        }
        if (fieldName == 'suppress_online_run') {
            setSelectedValue(suppressOnlineRun)
        }
        if (fieldName == 'maximum_calculation_lines') {
            setSelectedValue(maximumCalculationLines)
        }
        if (fieldName == 'posting_parallel_run') {
            setSelectedValue(postingParallelRun)
        }
        if (fieldName == 'calculation_performance_improvement') {
            setSelectedValue(calculationPerformanceImprovement)
        }
    };
    const handleReportingValue = (newValue) => {
        setReportingCategory(newValue);
    }
    const handleSuppressOnlineRun = (newValue) => {
        setSuppressOnlineRun(newValue.target.value);
    }
    const handleCalculationPerformanceImprovement = (newValue) => {
        setCalculationPerformanceImprovement(newValue.target.value);
    }
    const handleMaximumCalculationLines = (newValue) => {
        const value = newValue.target.value;

        // Allow only integers
        if (/^\d*$/.test(value)) {
            setMaximumCalculationLines(value);
        }
    }
    const handlePostingParallelRun = (newValue) => {
        const value = newValue.target.value;

        // Allow only integers
        if (/^\d*$/.test(value)) {
            setPostingParallelRun(value);
        }
    }

    function handleSubmit(clear) {
        var data;
        if (selectedRowName === "workflow_assignment") {
            if (clear === 'clear') {
                data = {
                    "workflow_assignment": selectedRowName == 'workflow_assignment' ? '' : workflowName,
                };
            } else {
                data = {
                    "config_fields": workflowName,
                };
            }
            if (currentID != 0) {
                props.onSubmit(data, 'edit', currentID, selectedRowName);
            }
            else {
                props.onSubmit(data, 'create', null, selectedRowName);
            }
        }
        else if (selectedRowName === "suppress_online_run") {
            if (clear === 'clear') {
                data = {
                    // "workflow_assignment": selectedRowName == 'workflow_assignment' ? '' : workflowName,
                    // "reporting_category": selectedRowName == 'reporting_category' ? '' : reportingCategory,
                    // "config_fields": [],
                };
            } else {
                data = {
                    "config_fields": Array.isArray(suppressOnlineRun) ? suppressOnlineRun : [suppressOnlineRun],
                };
            }
            if (currentSuppressID != 0) {
                props.onSubmit(data, 'edit', currentSuppressID, selectedRowName);
            }
            else {
                props.onSubmit(data, 'create', null, selectedRowName);
            }
        }
        else if (selectedRowName === "maximum_calculation_lines") {
            if (clear === 'clear') {
                data = {

                };
            } else {
                data = {
                    "config_fields": Array.isArray(maximumCalculationLines) ? maximumCalculationLines : [maximumCalculationLines],
                };
            }
            if (currentLinesID != 0) {
                props.onSubmit(data, 'edit', currentLinesID, selectedRowName);
            }
            else {
                props.onSubmit(data, 'create', null, selectedRowName);
            }
        }
        else if (selectedRowName === "posting_parallel_run") {
            if (clear === 'clear') {
                data = {

                };
            } else {
                data = {
                    "config_fields": Array.isArray(postingParallelRun) ? postingParallelRun : [postingParallelRun],
                };
            }
            if (postingParallelRunID != 0) {
                props.onSubmit(data, 'edit', postingParallelRunID, selectedRowName);
            }
            else {
                props.onSubmit(data, 'create', null, selectedRowName);
            }
        }
        else if (selectedRowName === "calculation_performance_improvement") {
            if (clear === 'clear') {
                data = {
                    // "workflow_assignment": selectedRowName == 'workflow_assignment' ? '' : workflowName,
                    // "reporting_category": selectedRowName == 'reporting_category' ? '' : reportingCategory,
                    // "config_fields": [],
                };
            } else {
                data = {
                    "config_fields": Array.isArray(calculationPerformanceImprovement) ? calculationPerformanceImprovement : [calculationPerformanceImprovement],
                };
            }
            if (currentCalculationID != 0) {
                props.onSubmit(data, 'edit', currentCalculationID, selectedRowName);
            }
            else {
                props.onSubmit(data, 'create', null, selectedRowName);
            }
        }
        else {
            if (clear === 'clear') {
                data = {
                    // "workflow_assignment": selectedRowName == 'workflow_assignment' ? '' : workflowName,
                    // "reporting_category": selectedRowName == 'reporting_category' ? '' : reportingCategory,
                    // "config_fields": [],
                };
            } else {
                data = {
                    "config_fields": reportingCategory,
                };
            }
            if (currentReportID != 0) {
                props.onSubmit(data, 'edit', currentReportID, selectedRowName);
            }
            else {
                props.onSubmit(data, 'create', null, selectedRowName);
            }
        }

    }

    return (
        <div className={clsx(classes.root, className)}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Configuration</Typography>
            </div>
            <div className={clsx(classes.addRoot, className)} style={{ marginTop: '6px' }}>

                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container} >
                            <Grid container >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Field Name' capitalize={true} data={selectedRowName ? selectedRowName.replace(/_/g, ' ') : 'Your selected field name appears here.'} twoline='true' />
                                </Grid>
                                {selectedRowName &&
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        style={{ paddingLeft: 15, marginTop: 20 }}
                                    >
                                        <FormLabel error={false}>
                                            Field Value
                                        </FormLabel>
                                        <div>
                                            {
                                                (selectedRowName == 'maximum_calculation_lines') &&
                                                <OutlinedInput
                                                    type='number'
                                                    inputProps={{ step: 1 }}
                                                    value={maximumCalculationLines}
                                                    style={{ width: '100%' }}
                                                    onChange={handleMaximumCalculationLines}
                                                />
                                            }
                                            {
                                                (selectedRowName == 'posting_parallel_run') &&
                                                <OutlinedInput
                                                    type='number'
                                                    inputProps={{ step: 1 }}
                                                    value={postingParallelRun}
                                                    style={{ width: '100%' }}
                                                    onChange={handlePostingParallelRun}
                                                />
                                            }
                                            {(selectedRowName == 'reporting_category') &&
                                                <ChipInput data={reportingCategory} onChange={handleReportingValue} />
                                            }
                                            {(selectedRowName == 'suppress_online_run') &&
                                                <Select
                                                    value={suppressOnlineRun}
                                                    onChange={handleSuppressOnlineRun}
                                                    displayEmpty
                                                    style={{ width: '100%', marginTop: 0, textTransform: 'capitalize', }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value={"Yes"} key={"Yes"} >
                                                        yes
                                                    </MenuItem>
                                                    <MenuItem value={"No"} key={"No"} >
                                                        No
                                                    </MenuItem>
                                                </Select>
                                            }
                                            {(selectedRowName === 'workflow_assignment') && (
                                                <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    multiple
                                                    value={workflowName}
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    style={{ maxHeight: "50px", width: '100%', height: 36, textTransform: 'capitalize' }}
                                                    input={<OutlinedInput />}
                                                    onOpen={() => handleOpen()}
                                                    onClose={handleClose}
                                                    open={open}
                                                    renderValue={(appType) => {
                                                        var priceType = []
                                                        priceType = Object.entries(workflowNameArray).filter(([key, value]) => appType.includes(key)).map(([key, value]) => value)
                                                        return priceType.join(", ")
                                                    }}
                                                    className={classes.multiSelect}
                                                >
                                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')}>
                                                        <Checkbox color='primary' checked={isAllSelected} />
                                                        <ListItemText primary='Select All' />
                                                    </MenuItem>
                                                    {workflowNameArray &&
                                                        Object.entries(workflowNameArray)
                                                            .map(([key, value]) => (
                                                                <MenuItem onClick={(event) => handleSelectValues(event, key)} value={key} key={key}>
                                                                    <Checkbox color='primary' checked={selected.indexOf(key) > -1} />
                                                                    <ListItemText primary={value} />
                                                                </MenuItem>
                                                            ))}
                                                    <div className={classes.dropdownAction}>
                                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('identifier')}>
                                                            Apply
                                                        </Button>
                                                    </div>
                                                </Select>
                                            )}
                                            {(selectedRowName == 'calculation_performance_improvement') &&
                                                <Select
                                                    value={calculationPerformanceImprovement}
                                                    onChange={handleCalculationPerformanceImprovement}
                                                    displayEmpty
                                                    style={{ width: '100%', marginTop: 0, textTransform: 'capitalize', }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value={"Yes"} key={"Yes"} >
                                                        yes
                                                    </MenuItem>
                                                    <MenuItem value={"No"} key={"No"} >
                                                        No
                                                    </MenuItem>
                                                </Select>
                                            }
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.buttonContainer}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ padding: 5 }}
                                        startIcon={<CheckIcon />}
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        //disabled={!putActive}
                                        onClick={() => handleSubmit()}
                                    > Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                        startIcon={<CheckIcon />}
                                        //disabled={!putActive}
                                        onClick={() => handleSubmit('clear')}
                                    >Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="EXISTING VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ width: '40%' }}>Field Name</TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('workflow_assignment')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    WorkFlow Assignment
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {workflowData && workflowData.length > 0 ? workflowData?.toString().replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('reporting_category')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Reporting Category
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {reportingCategoryData && reportingCategoryData ? reportingCategoryData.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('suppress_online_run')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Suppress Online Run
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {suppressOnlineRunData && suppressOnlineRunData ? suppressOnlineRunData.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('maximum_calculation_lines')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Maximum Calculation Lines
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {maximumCalculationData && maximumCalculationData ? maximumCalculationData.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('posting_parallel_run')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Posting Parallel Run
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {postingParallelRunData && postingParallelRunData ? postingParallelRunData.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('calculation_performance_improvement')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Calculation Performance Improvement
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {calculationPerformanceImprovementData && calculationPerformanceImprovementData ? calculationPerformanceImprovementData.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        claimData: state.addMultipleConfigurationData.configdesigner,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, field, type, selectedRowName) => dispatch(createConfigurationDesigner(data, field, type, selectedRowName)),
        getClaimData: () => dispatch(getConfigurationDesigner()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationDesign);